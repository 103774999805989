import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dropdown from "../../atoms/form/input/Dropdown";
import API from "../../../services/api";

export interface FacilitatorOption {
  value: string;
  label: string;
}

interface FacilitatorDropdownProps {
  selectedFacilitatorIds: string[];
  onFacilitatorChange: (facilitatorIds: string[]) => void;
}

const FacilitatorDropdown: React.FC<FacilitatorDropdownProps> = ({
  selectedFacilitatorIds,
  onFacilitatorChange,
}) => {
  const [users, setUsers] = useState<API.UserResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await API.getUsers({
          status: "active",
        });
        setUsers(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const prepareFacilitatorOptions = useCallback(
    (users: API.UserResponse[]): FacilitatorOption[] => {
      return users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
    },
    [],
  );

  const handleFacilitatorChange = useCallback(
    (selectedOptions: FacilitatorOption[]) => {
      const facilitatorIds = selectedOptions.map((option) => option.value);
      onFacilitatorChange(facilitatorIds);
    },
    [onFacilitatorChange],
  );

  const placeholderText = useMemo(() => {
    if (isLoading) return "Loading...";
    if (users.length === 0) return "No facilitators available for this client";
    return "Select facilitators";
  }, [isLoading, users.length]);

  return (
    <Dropdown
      isVerticalLayout
      data-test="facilitator-dropdown"
      isMulti={true}
      label="Assign Facilitators"
      selectProps={{
        options: prepareFacilitatorOptions(users),
        value: prepareFacilitatorOptions(
          users.filter((user) => selectedFacilitatorIds.includes(user.id)),
        ),
        onChange: handleFacilitatorChange,
        placeholder: placeholderText,
        isLoading: isLoading,
      }}
    />
  );
};

export default FacilitatorDropdown;
