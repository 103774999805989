import React, { useCallback } from "react";
import ChallengeModal from "../../organisms/challenge-modal/ChallengeModal";

import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";
import ConfirmModal from "../../organisms/confirm-modal/ConfirmModal";
import { pluralise } from "../../../lib/pluralise";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  template: API.ReportingTemplateResponse;
}

function DeleteReportingTemplate({
  isOpen,
  onComplete,
  onClose,
  template,
}: Props) {
  const callback = useCallback(
    () => API.deleteReportingTemplate(template.id),
    [template],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  if (template.eventsCount > 0) {
    return (
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={onClose}
        title="Delete events"
        description={`This template cannot be deleted as it is being used by ${template.eventsCount} ${pluralise("event", template.eventsCount)}. Please make sure it is not in use before deleting.`}
        confirmTitle={"Ok"}
      />
    );
  }

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to delete this template."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the template."
      title={`Delete template ${template.name}?`}
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteReportingTemplate;
