import React, { useCallback, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Icon, { IconType } from "../../../atoms/icon/Icon";
import Text from "../../../atoms/text/Text";
import HoverableIcon from "../../../atoms/icon/HoverableIcon";
import Table from "../../../atoms/table/Table";

import { shortDate } from "../../../../lib/date";
import Clickable from "../../../atoms/clickable/Clickable";
import ToggleEvent from "../event/components/ToggleEvent";
import DeleteEvent from "../event/components/DeleteEvent";
import StickyBar from "../../../organisms/sticky-bar/StickyBar";
import EmptyList from "../../../organisms/empty-list/EmptyList";
import IconButton from "../../../molecules/iconbutton/IconButton";
import RoleRequired from "../../../molecules/role-required/RoleRequired";
import Tooltip from "../../../atoms/tooltip/Tooltip";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import { ThemeColours } from "../../../../types/theme";
import { useCurrentUser } from "../../../../context/userContext";
import FacilitatorHidden from "../../../molecules/facilitator-hidden/FacilitatorHidden";

const TOOLTIP_ID = "event-table-tooltip";

export interface EventsWithStatus extends API.EventResponseSummary {
  status?: GameAPI.EventStatus["status"];
  statusText?: string;
  statusIcon?: IconType;
  statusColour?: ThemeColours;
}

interface Props {
  events: EventsWithStatus[];
  refreshEvents: () => void;
  showStatus?: boolean;
}

const getStreamName = (event: API.EventResponseSummary) => {
  let result = "-";
  if (event.streamName) {
    result = event.streamName;
  }
  return result;
};

interface EventState {
  eventModalOpen: boolean;
  deleteModalOpen: boolean;
  eventSelected: API.EventResponseSummary | null;
}

const STICKY_BAR_HEIGHT = 8;

function EventsTable({ events, refreshEvents, showStatus }: Props) {
  const user = useCurrentUser();
  const showStickybar = useMemo(() => {
    return user?.type !== "facilitator";
  }, [user?.type]);
  const [{ eventModalOpen, deleteModalOpen, eventSelected }, setEventState] =
    useState<EventState>({
      eventModalOpen: false,
      deleteModalOpen: false,
      eventSelected: null,
    });
  const history = useHistory();

  const onEventToggleComplete = useCallback(() => {
    setEventState((prevState) => ({
      ...prevState,
      eventModalOpen: false,
      eventSelected: null,
    }));
    refreshEvents();
  }, [refreshEvents]);

  const onEventDeleteComplete = useCallback(() => {
    setEventState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      eventSelected: null,
    }));
    refreshEvents();
  }, [refreshEvents]);

  const handleDeleteClick = useCallback(
    (e: API.EventResponseSummary) => (event: any) => {
      event.stopPropagation();
      setEventState({
        eventModalOpen: false,
        deleteModalOpen: true,
        eventSelected: e,
      });
    },
    [],
  );

  const handleCloseClick = useCallback(
    (e: API.EventResponseSummary) => (event: any) => {
      event.stopPropagation();
      setEventState({
        eventModalOpen: true,
        deleteModalOpen: false,
        eventSelected: e,
      });
    },
    [],
  );

  const handleEventClick = useCallback(
    (e: API.EventResponseSummary) => (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      history.push(`/events/${e.id}/view`);
    },
    [history],
  );

  const handleCloneClick = useCallback(
    (e: API.EventResponseSummary) => (event: any) => {
      event.stopPropagation();
      history.push(`/events/${e.id}/clone`);
    },
    [history],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      <Tooltip id="clone-event">
        <>
          <b>Clone Event</b>
          <p>
            Create a new event using the details <br /> of this event as a
            starting point.
          </p>
        </>
      </Tooltip>
      <Tooltip id="closed-event">
        <>
          <b>Open Event</b>
          <p>
            This will re-open the event and it <br />
            will be accessible to participants.
          </p>
        </>
      </Tooltip>
      <Tooltip id="open-event">
        <>
          <b>Close Event</b>
          <p>
            This will close the event. Participants <br />
            will no longer be able to access the event.
          </p>
        </>
      </Tooltip>
      {eventModalOpen && !!eventSelected && (
        <ToggleEvent
          isOpen={eventModalOpen}
          onClose={() =>
            setEventState((prevState) => ({
              ...prevState,
              eventModalOpen: false,
              eventSelected: null,
            }))
          }
          onComplete={onEventToggleComplete}
          event={eventSelected}
        />
      )}
      {deleteModalOpen && !!eventSelected && (
        <DeleteEvent
          isOpen={deleteModalOpen}
          onClose={() =>
            setEventState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
              eventSelected: null,
            }))
          }
          onComplete={onEventDeleteComplete}
          event={eventSelected}
        />
      )}
      {showStickybar && (
        <StickyBar height={STICKY_BAR_HEIGHT}>
          <FacilitatorHidden>
            <Link data-test="add-event" to="/events/add">
              <IconButton icon="addEvent" text="Add Event" />
            </Link>
          </FacilitatorHidden>
          {process.env.REACT_APP_ALLOW_IMPORT_EVENTS === "true" && (
            <RoleRequired roles={["superadmin"]}>
              <Link to="/events/import">
                <IconButton text="Import Event" icon="import" />
              </Link>
            </RoleRequired>
          )}
        </StickyBar>
      )}
      {!events.length && (
        <EmptyList icon="events" message="There are no events yet" />
      )}
      {!!events.length && (
        <Table
          stickyHeader={showStickybar}
          stickyTop={STICKY_BAR_HEIGHT}
          clickable
        >
          <thead>
            <tr>
              <th>Event Date</th>
              <th>Client</th>
              <th>Event Name</th>
              <th>Stream</th>
              <th>Pax #</th>
              {showStatus && <th>Status</th>}
              <th className="right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {events.map((e) => (
              <tr
                className="eventsTable-row"
                key={e.id}
                data-test={e.id}
                onClick={handleEventClick(e)}
              >
                <td>{shortDate(e.date)}</td>
                <td>{e.client}</td>
                <td>{e.name}</td>
                <td>{getStreamName(e)}</td>
                <td>{e.participantsCount}</td>
                {showStatus && (
                  <td>
                    <InlineGroup verticalCenter>
                      {e.statusIcon && (
                        <Icon
                          noMargin
                          type={e.statusIcon}
                          colour={e.statusColour}
                        />
                      )}
                      <Text style={{ marginLeft: 4 }} colour={e.statusColour}>
                        {e.statusText}
                      </Text>
                    </InlineGroup>
                  </td>
                )}
                <td className="right">
                  <Icon
                    type="search"
                    tt={{ content: "View Event", id: TOOLTIP_ID }}
                  />
                  <FacilitatorHidden>
                    <Clickable
                      data-test={`clone-${e.id}`}
                      onClick={handleCloneClick(e)}
                    >
                      <Icon
                        type="copy"
                        tt={{
                          id: "clone-event",
                        }}
                      />
                    </Clickable>
                  </FacilitatorHidden>
                  <Clickable
                    data-test={`toggle-${e.id}`}
                    onClick={handleCloseClick(e)}
                  >
                    <HoverableIcon
                      hoverType={e.closed ? "unlock" : "lock"}
                      type={e.closed ? "lock" : "unlock"}
                      tt={{
                        id: e.closed ? "closed-event" : "open-event",
                      }}
                    />
                  </Clickable>
                  <FacilitatorHidden>
                    {e.closed && (
                      <Clickable
                        data-test={`delete-${e.id}`}
                        onClick={handleDeleteClick(e)}
                      >
                        <Icon
                          type="trash"
                          tt={{
                            content: "Delete event",
                            id: TOOLTIP_ID,
                          }}
                        />
                      </Clickable>
                    )}
                  </FacilitatorHidden>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default EventsTable;
