import { useCallback, useEffect, useMemo } from "react";
import API from "../services/api";
import { useAPIRequestRunner } from "./useAPIRequest";

export const useGameStatus = (eventId: string) => {
  const {
    state: { inProgress, data, error },
    run,
  } = useAPIRequestRunner<API.GameStatus>();

  const doApiRequest = useCallback(
    () => run(API.getGameStatus(eventId)),
    [eventId, run],
  );

  useEffect(() => {
    doApiRequest();
  }, [doApiRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doApiRequest,
    }),
    [inProgress, data, error, doApiRequest],
  );
};
