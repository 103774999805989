import React, { useState, useCallback } from "react";
import Card from "../../../../atoms/card/Card";
import RoundResultsContainer from "../../components/RoundsResultsContainer";
import Text from "../../../../atoms/text/Text";
import { useResultsContext } from "../../context/context";
import EditableText from "../../../../atoms/text/EditableText";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import AddSectionModal from "../../../reporting-templates/actions/AddSectionModal";
import Icon from "../../../../atoms/icon/Icon";
import "./RoundResultsCustomLayoutBuilder.scss";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import ParseHtml from "../../components/ParseHtmlIntoResultsTest";
import Clickable from "../../../../atoms/clickable/Clickable";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toWebpBlobs } from "../../../../../lib/images";
import API from "../../../../../services/api";

import EditMediaModal from "../../../reporting-templates/actions/EditMediaModal";
import RowSection from "./RowSection";
import EditRowModal from "./EditRowModal";
import ImageSection from "./ImageSection";
import VideoSection from "./VideoSection";
interface Props {
  data: API.ReportingLayoutCustomConfig;
}
const RoundResultsCustomLayoutBuilder: React.FC<Props> = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditMediaModal, setShowEditMediaModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sectionToDeleteIndex, setSectionToDeleteIndex] = useState<
    number | null
  >(null);
  const context = useResultsContext();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setUploading] = useState(false);
  const [editingMediaIndex, setEditingMediaIndex] = useState<number | null>(
    null,
  );
  const [editingMediaType, setEditingMediaType] = useState<"image" | "video">(
    "image",
  );
  const [showEditRowModal, setShowEditRowModal] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null);

  const handleEditRow = useCallback((index: number) => {
    setEditingRowIndex(index);
    setShowEditRowModal(true);
  }, []);
  const handleSaveRowEdit = useCallback(
    (columns: number) => {
      if (editingRowIndex !== null && context.onCustomTemplateEdit) {
        const newItems = [...data.items];
        const rowItem = newItems[
          editingRowIndex
        ] as API.ReportingLayoutCustomSlideRowOption;

        // Adjust the number of items in the row
        while (rowItem.items.length < columns) {
          rowItem.items.push({ type: "text", value: "" });
        }
        if (rowItem.items.length > columns) {
          rowItem.items = rowItem.items.slice(0, columns);
        }

        context.onCustomTemplateEdit({
          config: {
            ...data,
            items: newItems,
          },
        });
      }
      setShowEditRowModal(false);
      setEditingRowIndex(null);
    },
    [context, data, editingRowIndex],
  );
  const [editingItemIndex, setEditingItemIndex] = useState<number | null>(null);
  const handleEditMedia = (
    index: number,
    type: "image" | "video",
    rowItemIndex?: number,
  ) => {
    setEditingMediaIndex(index);
    setEditingMediaType(type);
    setEditingItemIndex(rowItemIndex !== undefined ? rowItemIndex : null);
    setShowEditMediaModal(true);
  };
  const handleSaveMediaDimensions = (
    width: number,
    height: number,
    unit: "px" | "%",
    alignment: "left" | "center" | "right",
  ) => {
    if (editingMediaIndex !== null && context.onCustomTemplateEdit) {
      const newItems = [...data.items];
      const currentItem = newItems[editingMediaIndex];

      if (editingItemIndex !== null) {
        // Row item
        if (currentItem.type === "row") {
          const mediaItem = currentItem.items[editingItemIndex];
          if (mediaItem.type === editingMediaType) {
            currentItem.items[editingItemIndex] = {
              ...mediaItem,
              width,
              height,
              unit,
              alignment,
            };
          }
        }
      } else {
        // Non-row item
        if (currentItem.type === editingMediaType) {
          newItems[editingMediaIndex] = {
            ...currentItem,
            width,
            height,
            unit,
            alignment,
          };
        }
      }

      context.onCustomTemplateEdit({
        config: {
          ...data,
          items: newItems,
        },
      });
    }
    setShowEditMediaModal(false);
    setEditingMediaIndex(null);
    setEditingItemIndex(null);
  };

  const handleInsertImageClick = useCallback(() => {
    setShowModal(false);
    if (context.onCustomTemplateEdit) {
      const newImageItem: API.ReportingLayoutCustomSlideImageOption = {
        type: "image",
        imageId: "",
        imageUrl: "",
      };
      context.onCustomTemplateEdit({
        config: {
          ...data,
          items: [...data.items, newImageItem],
        },
      });
    }
  }, [context, data]);
  const onDrop = useCallback(
    (files: File[], index: number, itemIndex: number) => {
      (async () => {
        setUploading(true);
        setUploadError(null);

        const newFile = await toWebpBlobs(files[0]);
        if (newFile && newFile.size > 6000000) {
          setUploadError(
            "File size must <= 10M, please try again with a smaller image",
          );
          setUploading(false);
          return;
        }

        if (newFile) {
          try {
            const image = await API.uploadReportingImage(newFile);
            if (context.onCustomTemplateEdit) {
              const newItems = [...data.items];
              if (itemIndex === -1) {
                // This is a non-row item
                newItems[index] = {
                  type: "image",
                  imageId: image.id,
                  imageUrl: "",
                  width: 200,
                  height: 200,
                  alignment: "center",
                };
              } else {
                // This is a row item
                const rowItem = newItems[
                  index
                ] as API.ReportingLayoutCustomSlideRowOption;
                rowItem.items[itemIndex] = {
                  type: "image",
                  imageId: image.id,
                  imageUrl: "",
                  width: 200,
                  height: 200,
                  alignment: "center",
                };
              }
              context.onCustomTemplateEdit({
                config: {
                  ...data,
                  items: newItems,
                },
              });
            }
            setUploading(false);
            setUploadError(null);
          } catch (e) {
            console.error("Error during upload:", e);
            setUploading(false);
            setUploadError("Something went wrong when uploading file");
          }
        }
      })();
    },
    [context, data],
  );
  const getCurrentMediaProps = (
    index: number | null,
    itemIndex: number | null,
    items: API.ReportingLayoutCustomSlideOptions[],
    mediaType: "image" | "video",
  ): {
    width: number;
    height: number;
    unit: "px" | "%";
    alignment: "left" | "center" | "right";
  } => {
    if (index === null) {
      return { width: 200, height: 200, unit: "px", alignment: "left" };
    }

    const item = items[index];

    if (itemIndex !== null && item.type === "row") {
      const rowItem = (item as API.ReportingLayoutCustomSlideRowOption).items[
        itemIndex
      ] as
        | API.ReportingLayoutCustomSlideImageOption
        | API.ReportingLayoutCustomSlideVideoOption;
      if (rowItem.type !== mediaType) {
        return { width: 200, height: 200, unit: "px", alignment: "left" };
      }
      return {
        width: rowItem.width || (mediaType === "image" ? 200 : 600),
        height: rowItem.height || (mediaType === "image" ? 200 : 500),
        unit: rowItem.unit || "px",
        alignment: rowItem.alignment || "left",
      };
    } else if (item.type === mediaType) {
      const mediaItem = item as
        | API.ReportingLayoutCustomSlideImageOption
        | API.ReportingLayoutCustomSlideVideoOption;
      return {
        width: mediaItem.width || (mediaType === "image" ? 200 : 600),
        height: mediaItem.height || (mediaType === "image" ? 200 : 500),
        unit: mediaItem.unit || "px",
        alignment: mediaItem.alignment || "left",
      };
    }

    return { width: 200, height: 200, unit: "px", alignment: "left" };
  };
  const handleClickAddSection = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleInsertVideoClick = useCallback(() => {
    setShowModal(false);
    if (context.onCustomTemplateEdit) {
      const newItem: API.ReportingLayoutCustomSlideVideoOption = {
        type: "video",
        videoId: "",
        videoUrl: "",
      };

      context.onCustomTemplateEdit({
        config: {
          ...data,
          items: [...data.items, newItem],
        },
      });
    }
  }, [context, data]);

  const handleVideoUpload = useCallback(
    (files: File[], index: number, itemIndex: number) => {
      (async () => {
        setUploading(true);
        setUploadError(null);

        try {
          const video = await API.uploadReportingVideo(files[0]);
          if (context.onCustomTemplateEdit) {
            const newItems = [...data.items];
            if (itemIndex === -1) {
              // This is a non-row item
              newItems[index] = {
                type: "video",
                videoId: video.id,
                videoUrl: "",
                width: 600,
                height: 500,
              };
            } else {
              // This is a row item
              const rowItem = newItems[
                index
              ] as API.ReportingLayoutCustomSlideRowOption;
              rowItem.items[itemIndex] = {
                type: "video",
                videoId: video.id,
                videoUrl: "",
                width: 600,
                height: 500,
              };
            }
            context.onCustomTemplateEdit({
              config: {
                ...data,
                items: newItems,
              },
            });
          }
          setUploading(false);
          setUploadError(null);
        } catch (e) {
          console.error("Error during video upload:", e);
          setUploading(false);
          setUploadError("Something went wrong when uploading video");
        }
      })();
    },
    [context, data],
  );

  const handleInsertTextClick = useCallback(() => {
    setShowModal(false);
    if (context.onCustomTemplateEdit) {
      const newItem: API.ReportingLayoutCustomSlideTextOption = {
        type: "text",
        value: "Insert Text Here",
      };
      const newItems = [...data.items, newItem];
      context.onCustomTemplateEdit({
        config: {
          ...data,
          items: newItems,
        },
      });
    }
  }, [context, data]);

  const onChange = useCallback(
    (index: number, val: string) => {
      if (context.onCustomTemplateEdit) {
        const newItems = [...data.items];
        newItems[index] = { type: "text", value: val };
        context.onCustomTemplateEdit({
          config: {
            ...data,
            items: newItems,
          },
        });
      }
    },
    [context, data],
  );
  const handleDeleteClick = useCallback((index: number) => {
    setSectionToDeleteIndex(index);
    setShowConfirmModal(true);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (sectionToDeleteIndex !== null && context.onCustomTemplateEdit) {
      const newItems = data.items.filter((_, i) => i !== sectionToDeleteIndex);
      context.onCustomTemplateEdit({
        config: {
          ...data,
          items: newItems,
        },
      });
    }
    setShowConfirmModal(false);
    setSectionToDeleteIndex(null);
  }, [context, data, sectionToDeleteIndex]);

  const handleCancelDelete = useCallback(() => {
    setShowConfirmModal(false);
    setSectionToDeleteIndex(null);
  }, []);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(data.items);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    if (context.onCustomTemplateEdit) {
      context.onCustomTemplateEdit({
        config: {
          ...data,
          items: items,
        },
      });
    }
  };
  const [currentRowIndex, setCurrentRowIndex] = useState<number | null>(null);
  const handleInsertRowClick = useCallback(() => {
    setShowModal(false);
    if (context.onCustomTemplateEdit) {
      const newRowItem: API.ReportingLayoutCustomSlideRowOption = {
        type: "row",
        items: [
          { type: "text", value: "" },
          { type: "text", value: "" },
        ],
      };
      context.onCustomTemplateEdit({
        config: {
          ...data,
          items: [...data.items, newRowItem],
        },
      });
    }
  }, [context, data]);
  const handleAddRowItem = useCallback(
    (rowIndex: number, itemIndex: number) => {
      setShowModal(true);
      setCurrentRowIndex(rowIndex);
      setCurrentItemIndex(itemIndex);
    },
    [],
  );

  const handleEditRowItem = useCallback(
    (
      rowIndex: number,
      itemIndex: number,
      newItem: API.ReportingLayoutCustomSlideRowItem,
    ) => {
      if (context.onCustomTemplateEdit) {
        const newItems = [...data.items];
        const rowItem = newItems[
          rowIndex
        ] as API.ReportingLayoutCustomSlideRowOption;
        rowItem.items[itemIndex] = newItem;
        context.onCustomTemplateEdit({
          config: {
            ...data,
            items: newItems,
          },
        });
      }
    },
    [context, data],
  );

  const handleDeleteRowItem = useCallback(
    (rowIndex: number, itemIndex: number) => {
      if (context.onCustomTemplateEdit) {
        const newItems = [...data.items];
        const rowItem = newItems[
          rowIndex
        ] as API.ReportingLayoutCustomSlideRowOption;
        rowItem.items.splice(itemIndex, 1);
        context.onCustomTemplateEdit({
          config: {
            ...data,
            items: newItems,
          },
        });
      }
    },
    [context, data],
  );
  const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null);
  const handleInsertRowItem = useCallback(
    (
      rowIndex: number,
      itemIndex: number,
      newItem: API.ReportingLayoutCustomSlideRowItem,
    ) => {
      if (context.onCustomTemplateEdit) {
        const newItems = [...data.items];
        const rowItem = newItems[
          rowIndex
        ] as API.ReportingLayoutCustomSlideRowOption;
        rowItem.items[itemIndex] = newItem; // Replace the item at the specific index
        context.onCustomTemplateEdit({
          config: {
            ...data,
            items: newItems,
          },
        });
      }
      setShowModal(false);
      setCurrentRowIndex(null);
      setCurrentItemIndex(null);
    },
    [context, data],
  );
  const getAlignment = (
    item:
      | API.ReportingLayoutCustomSlideImageOption
      | API.ReportingLayoutCustomSlideVideoOption,
  ) => {
    return item.alignment || "left";
  };

  return (
    <RoundResultsContainer allowOverFlow>
      <AddSectionModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentRowIndex(null);
          setCurrentItemIndex(null);
        }}
        onInsertTextClick={() => {
          if (currentRowIndex !== null && currentItemIndex !== null) {
            handleInsertRowItem(currentRowIndex, currentItemIndex, {
              type: "text",
              value: "New Text",
            });
          } else {
            handleInsertTextClick();
          }
        }}
        onInsertImageClick={() => {
          if (currentRowIndex !== null && currentItemIndex !== null) {
            handleInsertRowItem(currentRowIndex, currentItemIndex, {
              type: "image",
              imageId: "",
              imageUrl: "",
            });
          } else {
            handleInsertImageClick();
          }
        }}
        onInsertVideoClick={() => {
          if (currentRowIndex !== null && currentItemIndex !== null) {
            handleInsertRowItem(currentRowIndex, currentItemIndex, {
              type: "video",
              videoId: "",
              videoUrl: "",
            });
          } else {
            handleInsertVideoClick();
          }
        }}
        onInsertRowClick={handleInsertRowClick}
      />
      <EditMediaModal
        isOpen={showEditMediaModal}
        onClose={() => {
          setShowEditMediaModal(false);
          setEditingMediaIndex(null);
          setEditingItemIndex(null);
        }}
        {...getCurrentMediaProps(
          editingMediaIndex,
          editingItemIndex,
          data.items,
          editingMediaType,
        )}
        onSave={handleSaveMediaDimensions}
        mediaType={editingMediaType}
      />
      <Card fullHeight wide>
        <DragDropContext onDragEnd={onDragEnd}>
          <VerticalGroup
            spaceBetweenElements={2}
            full
            className="custom-layout-container"
            wide
          >
            <Droppable droppableId="list">
              {(provided) => (
                <div
                  style={{ width: "100%", minHeight: "50px" }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="draggable-area"
                >
                  {data.items.length > 0 ? (
                    data.items.map((item, index) => (
                      <Draggable
                        key={`item-${index}`}
                        draggableId={`item-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              width: "100%",
                              ...provided.draggableProps.style,
                            }}
                            className="section draggable-item"
                          >
                            {context.allowCustomTemplateEdits && (
                              <InlineGroup
                                spaceBetweenElements={3}
                                className="section-buttons"
                              >
                                {item.type === "row" && (
                                  <Clickable
                                    onClick={() => handleEditRow(index)}
                                    className="action-button"
                                  >
                                    <Icon type="editRow" size={8} />
                                  </Clickable>
                                )}
                                {item.type === "image" && (
                                  <Clickable
                                    onClick={() =>
                                      handleEditMedia(index, "image")
                                    }
                                    className="action-button"
                                  >
                                    <Icon type="editImage" size={8} />
                                  </Clickable>
                                )}
                                {item.type === "video" && (
                                  <Clickable
                                    onClick={() =>
                                      handleEditMedia(index, "video")
                                    }
                                    className="action-button"
                                  >
                                    <Icon type="editVideo" size={8} />
                                  </Clickable>
                                )}
                                <Clickable
                                  onClick={() => handleDeleteClick(index)}
                                  className="action-button"
                                >
                                  <Icon type="trash" size={8} />
                                </Clickable>
                                <Icon
                                  className="action-button"
                                  type="draggable"
                                  size={8}
                                />
                              </InlineGroup>
                            )}
                            <div className="section-content">
                              {item.type === "text" ? (
                                context.allowCustomTemplateEdits ? (
                                  <EditableText
                                    key={`editable-text-${index}`}
                                    clickableStyle={{
                                      width: "100%",
                                    }}
                                    value={item.value}
                                    type="richText"
                                    onChange={(val: string) =>
                                      onChange(index, val)
                                    }
                                  />
                                ) : (
                                  <ParseHtml html={item.value} />
                                )
                              ) : item.type === "image" ? (
                                <ImageSection
                                  item={item}
                                  index={index}
                                  onDrop={onDrop}
                                  isUploading={isUploading}
                                  uploadError={uploadError}
                                  getAlignment={getAlignment}
                                />
                              ) : item.type === "video" ? (
                                <VideoSection
                                  item={item}
                                  index={index}
                                  handleVideoUpload={handleVideoUpload}
                                  isUploading={isUploading}
                                  uploadError={uploadError}
                                  getAlignment={getAlignment}
                                />
                              ) : null}

                              {item.type === "row" && (
                                <RowSection
                                  rowIndex={index}
                                  items={item.items}
                                  onAddItem={handleAddRowItem}
                                  onEditItem={handleEditRowItem}
                                  onDeleteItem={handleDeleteRowItem}
                                  onEditMedia={handleEditMedia}
                                  onDrop={onDrop}
                                  handleVideoUpload={handleVideoUpload}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <Text size="lg">
                      No items yet. Add a section to start dragging.
                    </Text>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {context.allowCustomTemplateEdits && (
              <InlineGroup
                border={{ style: "dashed", color: "grey1", width: "0.2rem" }}
                block
                center
                verticalCenter
                className="add-section-container"
                onClick={handleClickAddSection}
              >
                <InlineGroup verticalCenter center spaceBetweenElements={1}>
                  <Text colour="blue" size="2xl" className="icon-label">
                    Add Section
                  </Text>
                  <Icon colour="blue" type="plus" size={8} />
                </InlineGroup>
              </InlineGroup>
            )}
          </VerticalGroup>
        </DragDropContext>
      </Card>
      <EditRowModal
        isOpen={showEditRowModal}
        onClose={() => setShowEditRowModal(false)}
        onSave={handleSaveRowEdit}
        initialColumns={
          editingRowIndex !== null
            ? (
                data.items[
                  editingRowIndex
                ] as API.ReportingLayoutCustomSlideRowOption
              ).items.length
            : 2
        }
      />
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title="Remove Section"
        description="Are you sure you want to remove this section?"
        onCancel={handleCancelDelete}
      />
    </RoundResultsContainer>
  );
};
export default RoundResultsCustomLayoutBuilder;
