import React, { useCallback, useState, useEffect } from "react";
import {
  parseTimeLeftFromSeconds,
  differenceISOTime,
  formatAMPM,
} from "../../../lib/date";
import API from "../../../services/api";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import Text from "../../atoms/text/Text";
import { MINIMUM_SECONDS_LEFT } from "./GameController";
import Button from "../../atoms/button/Button";
import Icon from "../../atoms/icon/Icon";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import TimerProgress from "./timer/TimerProgress";
import GameConnections from "./GameConnections";
import "./timer/Timer.scss";
import classnames from "classnames";
import { Accordion } from "../../organisms/accordion/accordion";
import IconButton from "../../molecules/iconbutton/IconButton";
import useIsMobileOrTablet from "../../../hooks/useIsMobileOrTablet";

interface Props {
  game: API.GameStatus;
  round: number;
  event: API.EventResponse;
  setError: (err: string | null) => void;
  update: () => void;
  setScreen: (value: "timer" | "bonus" | "close") => void;
  handleLoginClick: (teamId: number) => void;
  handleBroadcastClick: () => void;
  handleSendWelcomeEmailsClick: () => void;
}

function FacilitatedRoundTimerScreen({
  game,
  round,
  event,
  setError,
  update,
  setScreen,
  handleLoginClick,
  handleBroadcastClick,
  handleSendWelcomeEmailsClick,
}: Props) {
  const isMobileOrTablet = useIsMobileOrTablet();
  const inProgress = game.status === "inprogress" || game.status === "wrapup";
  const [time, setTime] = useState(game.timeRemaining);
  const [active, setActive] = useState(
    inProgress && time > 0 && !game.pausedTime,
  );

  useEffect(() => {
    setTime(game.timeRemaining);
    const inProgress = game.status === "inprogress" || game.status === "wrapup";
    setActive(inProgress && !game.pausedTime);
  }, [game]);

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (active) {
      countdown = setInterval(async () => {
        setTime((time) => time - 1);
      }, 1000);
    }
    if (game.timeRemaining < 0) {
      setScreen("bonus");
    }
    return () => {
      clearInterval(countdown);
    };
  }, [active, game, setScreen]);

  const startGame = useCallback(async () => {
    if (game.status === "bonustime") {
      await API.updateTimer(game.gameId, round, game.timeRemaining);
    }
    await API.startRound(game.gameId, round);
    await API.unlockAllTeams(game.gameId, round);
  }, [game, round]);

  const resumeGame = useCallback(async () => {
    await API.unlockAllTeams(game.gameId, round);
  }, [game.gameId, round]);

  const pauseGame = useCallback(async () => {
    await API.lockAllTeams(game.gameId, round);
  }, [game.gameId, round]);

  const handlePlay = useCallback(async () => {
    setError(null);
    if (active) {
      await pauseGame();
    } else if (inProgress) {
      await resumeGame();
    } else {
      try {
        await startGame();
      } catch (err) {
        setError(err.message);
      }
    }
    setActive(!active);
    update();
  }, [
    active,
    inProgress,
    pauseGame,
    resumeGame,
    setActive,
    setError,
    startGame,
    update,
  ]);

  const handleTimeChange = async (seconds: number) => {
    if (time <= MINIMUM_SECONDS_LEFT && seconds < 0) return;
    const timeLeft = game.finishTime
      ? differenceISOTime(game.finishTime, null)
      : game.timeAllocated;
    try {
      if (timeLeft >= 0 && timeLeft + seconds < MINIMUM_SECONDS_LEFT) {
        await API.updateTimer(
          game.gameId,
          round,
          -(time - MINIMUM_SECONDS_LEFT),
        );
      } else {
        await API.updateTimer(game.gameId, round, seconds);
      }
    } catch (err) {
      setError(err.message);
      return;
    }
    update();
  };

  const timeLeft = parseTimeLeftFromSeconds(time, true);
  const maxTimerValue = time <= 300 ? 300 : game.timeAllocated;

  return (
    <InlineGroup
      spaceBetweenElements={isMobileOrTablet ? 0 : 2}
      block
      className={isMobileOrTablet ? "mobile-layout" : ""}
    >
      <VerticalGroup
        className={classnames("game-timer", {
          "width-90-percent": isMobileOrTablet,
          "width-40-percent": !isMobileOrTablet,
        })}
        spaceBetweenElements={2}
        center
      >
        <div
          style={{
            width: isMobileOrTablet ? "100%" : "60%",
            maxWidth: isMobileOrTablet ? "450px" : "",
          }}
        >
          <TimerProgress value={time} maxValue={maxTimerValue}>
            <VerticalGroup center>
              {timeLeft.displayType === "M" && (
                <Text
                  size="4xl"
                  colour={
                    time <= 60
                      ? "danger"
                      : time <= 300
                        ? "secondaryYellow"
                        : "secondaryBlue"
                  }
                  mono
                  medium
                >
                  {timeLeft.time.toUpperCase()}
                </Text>
              )}
              {timeLeft.displayType === "H" && (
                <Text
                  size={isMobileOrTablet ? "4xl" : "xl"}
                  colour="green"
                  mono
                  medium
                  respectNewLine
                  center
                >
                  {timeLeft.time.toUpperCase()}
                </Text>
              )}
              {timeLeft.displayType === "D" && (
                <Text
                  size={isMobileOrTablet ? "4xl" : "2xl"}
                  colour="green"
                  mono
                  medium
                >
                  {timeLeft.time.toUpperCase()}
                </Text>
              )}
              <Text size={isMobileOrTablet ? "xl" : "xl"} mono medium>
                {time <= 300 ? "FINAL 5 MINS" : "REMAINING"}
              </Text>
              <Text
                size={isMobileOrTablet ? "lg" : "xs"}
                mono
                medium
              >{`Round End Time: ${active ? formatAMPM(new Date(game.finishTime!)) : "-"}`}</Text>
            </VerticalGroup>
          </TimerProgress>
        </div>
        <InlineGroup spread verticalCenter>
          <InlineGroup className="controls">
            <div onClick={() => handleTimeChange(-60 * 10)}>
              -10<p>mins</p>
            </div>
            <div onClick={() => handleTimeChange(-60)}>
              -1<p>mins</p>
            </div>
          </InlineGroup>
          <Button
            colour={active ? "secondaryYellow" : "green"}
            className={`m-4 ${active ? "game-pause" : "game-play"}`}
            onClick={handlePlay}
          >
            <Icon
              type={active ? "pause" : "play"}
              colour="white"
              className={`timer-icon${active ? "" : "-play"}`}
            />
          </Button>
          <InlineGroup className="controls">
            <div onClick={() => handleTimeChange(60)}>
              +1<p>mins</p>
            </div>
            <div onClick={() => handleTimeChange(60 * 10)}>
              +10<p>mins</p>
            </div>
          </InlineGroup>
        </InlineGroup>
        {!isMobileOrTablet && (
          <IconButton
            block
            icon="broadcast"
            onClick={handleBroadcastClick}
            text="Broadcast"
          />
        )}
        {!isMobileOrTablet && round <= 1 && (
          <IconButton
            block
            wide={false}
            data-test="send-emails"
            icon="email"
            text="Send Welcome Emails"
            onClick={handleSendWelcomeEmailsClick}
          />
        )}
      </VerticalGroup>
      {isMobileOrTablet ? (
        <Accordion wide title="Teams" defaultState="collapse">
          <GameConnections
            teams={game.teams}
            gameId={game.gameId}
            event={event}
            handleLoginClick={handleLoginClick}
          />
        </Accordion>
      ) : (
        <GameConnections
          teams={game.teams}
          gameId={game.gameId}
          event={event}
          handleLoginClick={handleLoginClick}
        />
      )}
    </InlineGroup>
  );
}

export default FacilitatedRoundTimerScreen;
