import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import UserForm from "./UserForm";
import API from "../../../../services/api";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Container from "../../../atoms/page/Page";
import Banner from "../../../atoms/banner/Banner";

function EditUser() {
  const { userId } = useParams<{ userId: string }>();

  const callback = useCallback(() => {
    return API.getUser(userId);
  }, [userId]);

  const [{ inProgress, data, error }, doApiRequest] = useAPIRequest(
    callback,
    null,
  );

  useEffect(() => {
    doApiRequest();
  }, [doApiRequest]);

  const onSave = useCallback(
    (data: API.UserRequest) => {
      return API.editUser(userId, data);
    },
    [userId],
  );

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push(`/administration/users/${userId}/view`);
  }, [history, userId]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {data && (
        <UserForm
          user={{
            ...data,
            clientIds: data.clients?.map((c) => c.id) ?? [],
          }}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}
    </Container>
  );
}

export default EditUser;
