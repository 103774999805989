import React, { useMemo } from "react";
import { useGenericPreviewReportingData } from "../../../../../../hooks/usePreviewReportingData";
import VerticalGroup from "../../../../../atoms/verticalgroup/VerticalGroup";
import Banner from "../../../../../atoms/banner/Banner";
import LoadingSpinner from "../../../../../atoms/loadingspinner/LoadingSpinner";
import ReportingTemplatePreviewContent from "../../../../reporting-templates/components/ReportingTemplatePreviewContent";
import useDimensions from "../../../../../../hooks/useDimensions";
import { Noop } from "../../../../../../constants/functions";
import { PreviewSettings } from "../types";
import { useReportingTemplateList } from "../../../../../../hooks/useReportingTemplate";

interface Props {
  scenarioId: string;
  roundId: number;
  previewSettings: PreviewSettings;
}
const headingHeight = 10;
const containerPadding = 0;
const innerContainerPadding = 0;

const RENDERED_DIMENSIONS = { width: 1700, height: 800 };

const ResultsPreview = ({ roundId, scenarioId, previewSettings }: Props) => {
  const { data: templates, inProgress: templatesLoading } =
    useReportingTemplateList([], false);

  const reportingTemplateId = useMemo(() => {
    if (!templates || !templates.length) return null;
    return templates[0].id;
  }, [templates]);

  const { data, inProgress, error } = useGenericPreviewReportingData(
    reportingTemplateId,
    roundId,
    scenarioId,
    previewSettings,
  );

  const [
    { previewHeight, previewWidth, availableHeight, availableWidth },
    setContainerRef,
  ] = useDimensions(
    headingHeight,
    containerPadding,
    innerContainerPadding,
    RENDERED_DIMENSIONS,
  );

  return (
    <VerticalGroup full wide spaceBetweenElements={6} ref={setContainerRef}>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {(inProgress || templatesLoading) && <LoadingSpinner />}
      <VerticalGroup full wide>
        {data && previewHeight > 0 && (
          <ReportingTemplatePreviewContent
            key={`${previewHeight}${previewWidth}${availableHeight}${availableWidth}${reportingTemplateId}${roundId}`}
            round={roundId - 1}
            data={{
              ...data,
              layouts: data.layouts.filter(
                (layout) => layout.type === "news_room",
              ),
            }}
            mode="fullscreen"
            previewHeight={previewHeight}
            previewWidth={previewWidth}
            availableHeight={availableHeight}
            availableWidth={availableWidth}
            setMode={Noop}
            resultsView
            noAnimations
            hidePagination
            noAudio
          />
        )}
      </VerticalGroup>
    </VerticalGroup>
  );
};

export default ResultsPreview;
