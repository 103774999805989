import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import { TeamsWithColours } from "../RoundResultsPage";
import ResultsBarChart from "../charts/ResultsBarChart";
import { percentage, thousandSeparator } from "../../model/utils/formatters";
import ChartCard from "../components/ChartCard";
import { getYAxisDataV2 } from "../charts/yaxis";
import Card from "../../../atoms/card/Card";
import Icon from "../../../atoms/icon/Icon";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import { createBarChartTopLabel } from "../charts/BarChartTopLabel";
import { createLegend } from "../charts/BarChartLegend";
import { useResultsContext } from "../context/context";

interface Props {
  currencyDenomination: string;
  teams: TeamsWithColours[];
  wholesaleFundingIssues: ModelAPI.Reporting.WholesaleFundingIssuesTeamData[];
}

const RoundResultsCustomerSatisfaction: React.FC<Props> = ({
  currencyDenomination,
  teams,
  wholesaleFundingIssues,
}) => {
  const noWholesaleFundingIssues = useMemo(
    () => wholesaleFundingIssues.every((val) => val.value === 0),
    [wholesaleFundingIssues],
  );
  const data = useMemo(
    () =>
      wholesaleFundingIssues.map((teamValue) => {
        const team = teams.find((t) => t.teamId === teamValue.teamId);
        if (team == null) {
          throw new Error("Team should exist");
        }
        return {
          name: team.teamName,
          value: teamValue.value,
          key: `team${teamValue.teamId}`,
          teamId: teamValue.teamId,
          fill: team.colour,
          topLabel: createBarChartTopLabel(percentage),
          topValueToUse: teamValue.percentage,
          legend: createLegend(teams),
        };
      }),
    [wholesaleFundingIssues, teams],
  );

  const { minValue, maxValue, numberOfTicks } = useMemo(
    () =>
      getYAxisDataV2({
        pastRound1: true,
        min: 0,
        data: [{ name: "fake0", value: 0 }].concat(
          wholesaleFundingIssues.map((wsf) => ({
            name: "fakeName",
            value: wsf.value,
          })),
        ),
      }),
    [wholesaleFundingIssues],
  );

  const context = useResultsContext();
  const maxClicks = useMemo(
    () => (context.allowPresentationMode || context.readSyncState ? 1 : 0),
    [context.allowPresentationMode, context.readSyncState],
  );
  const onNext = useCallback(() => {
    if (context.clicks < maxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, maxClicks]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide>
        {noWholesaleFundingIssues ? (
          <Card flex wide sharp>
            <VerticalGroup full center wide>
              <VerticalGroup full center wide>
                <ResultsText size="2xl">
                  No Wholesale Funding Issues
                </ResultsText>
                <InlineGroup block fullHeight center verticalCenter>
                  <Icon type="sayno" size={72} />
                </InlineGroup>
              </VerticalGroup>
            </VerticalGroup>
          </Card>
        ) : (
          <ChartCard
            heading="Wholesale Funding Issues"
            currencyDenomination={currencyDenomination}
          >
            <ResultsBarChart
              data={data}
              formatter={(val: number) => thousandSeparator(val)}
              withoutCpuTeams
              minYAxisvalue={minValue}
              maxYAxisvalue={maxValue}
              numberOfTicks={numberOfTicks}
              shouldRenderLines={context.clicks === maxClicks}
            />
          </ChartCard>
        )}
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCustomerSatisfaction;
