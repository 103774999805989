import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { validate as uuidValidate } from "uuid";

import Container from "../../../atoms/page/Page";
import Sidebar from "./Sidebar";
import Businesses from "./Businesses";
import DealRoom from "./DealRoom";
import ExcoDecisions from "./ExcoDecisions";
import SpecialProjects from "./SpecialProjects";
import InfoPopups from "./InfoPopups";
import Labels from "./Labels";
import SummaryMetrics from "./SummaryMetrics";
import HelpManuals from "./helpmanuals/HelpManuals";
import AddHelpManual from "./helpmanuals/AddHelpManuals";
import FinancialSummaryMetrics from "./FinancialSummaryMetrics";
import HelpManualDetails from "./helpmanuals/HelpManualDetails";
import HelpManualEdit from "./helpmanuals/HelpManualEdit";
import BroadcastMessages from "./broadcastMessages/BroadcastMessages";
import EconomicScenarioBuilder from "./EconomicScenarioBuilder";
import GlobalModelVariables from "./globalModelVariables/GlobalModelVariables";
import ReportingLayouts from "./ReportingLayouts";

export interface Module {
  id: string;
  name: string;
}

const modules: Module[] = [
  {
    id: "economicScenarioBuilder",
    name: "ECONOMIC SCENARIO BUILDER",
  },
  {
    id: "globalModelVariables",
    name: "GLOBAL MODEL VARIABLES",
  },
  {
    id: "summaryMetric",
    name: "SUMMARY METRIC",
  },
  {
    id: "financialSummaryMetric",
    name: "FINANCIAL SUMMARY METRIC",
  },
  {
    id: "specialprojects",
    name: "SPECIAL PROJECTS",
  },
  {
    id: "excodecisions",
    name: "EXECUTIVE DECISIONS",
  },
  {
    id: "businesses",
    name: "BUSINESSES",
  },
  {
    id: "dealroom",
    name: "DEAL ROOM",
  },
  {
    id: "reporting",
    name: "REPORTING",
  },
  {
    id: "helpManuals",
    name: "HELP MANUALS",
  },
  {
    id: "broadcast",
    name: "BROADCAST MESSAGES",
  },
];

const displayModules: Module[] = [
  {
    id: "labels",
    name: "LABELS",
  },
  {
    id: "help",
    name: "INFO POPUPS",
  },
];

function Configuration() {
  const {
    moduleId = "economicScenarioBuilder",
    action = null,
    resourceId = "",
  } = useParams<{
    moduleId: string;
    resourceId?: string;
    action?: string;
  }>();
  const history = useHistory();

  const handleModuleChange = (moduleId: string) => {
    history.push(`/administration/configuration/${moduleId}`);
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Sidebar
        selected={moduleId}
        onChange={handleModuleChange}
        modules={modules}
        displayItems={displayModules}
      />
      <div style={{ flex: 1, width: "100%" }}>
        <Container>
          {moduleId === "dealroom" && <DealRoom />}
          {moduleId === "reporting" && <ReportingLayouts />}
          {moduleId === "excodecisions" && <ExcoDecisions />}
          {moduleId === "specialprojects" && <SpecialProjects />}
          {moduleId === "businesses" && <Businesses />}
          {moduleId === "help" && <InfoPopups />}
          {moduleId === "labels" && <Labels />}
          {moduleId === "summaryMetric" && <SummaryMetrics />}
          {moduleId === "financialSummaryMetric" && <FinancialSummaryMetrics />}
          {moduleId === "economicScenarioBuilder" && (
            <EconomicScenarioBuilder />
          )}
          {moduleId === "globalModelVariables" && <GlobalModelVariables />}
          {moduleId === "helpManuals" && action === null && <HelpManuals />}
          {moduleId === "helpManuals" && action === "add" && <AddHelpManual />}
          {moduleId === "helpManuals" &&
            uuidValidate(resourceId) &&
            action === "view" && (
              <HelpManualDetails helpManualId={resourceId} />
            )}
          {moduleId === "helpManuals" &&
            uuidValidate(resourceId) &&
            action === "edit" && <HelpManualEdit helpManualId={resourceId} />}
          {moduleId === "broadcast" && action === null && <BroadcastMessages />}
        </Container>
      </div>
    </div>
  );
}

export default Configuration;
