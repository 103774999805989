import React, { useCallback, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useParams, useHistory } from "react-router-dom";
import Container from "../../atoms/page/Page";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import ReportingTemplateDelete from "./ReportingTemplateDelete";
import Banner from "../../atoms/banner/Banner";
import IconButton from "../../molecules/iconbutton/IconButton";
import { useReportingTemplate } from "../../../hooks/useReportingTemplate";
import { CloneReportingTemplate } from "./actions/CloneReportingTemplate";
import api from "../../../services/api";
import { AddReportingTemplateLayout } from "./actions/AddReportingTemplateLayout";
import { AutoConfigure } from "./actions/Autoconfigure";
import { GenericPreview } from "./actions/GenericPreview";
import DeleteReportingTemplateLayout from "./ReportingTemplateDeleteLayout";
import { useGenericPreviewReportingData } from "../../../hooks/usePreviewReportingData";
import ReportingTemplateDetailsLayout from "./ReportingTemplateDetailsLayout";
import RoleRequired from "../../molecules/role-required/RoleRequired";

interface AssessmentState {
  deleteModalOpen: boolean;
  deleteLayoutModalOpen: boolean;
}

function ReportingTemplateDetails() {
  const { reportingTemplateId } = useParams<{ reportingTemplateId: string }>();
  const history = useHistory();
  const [apiError, setApiError] = useState<string | null>(null);
  const [selectedLayout, setSelectedLayout] =
    useState<API.ReportingTemplateLayoutResponse | null>(null);
  const [apiUpdating, setApiUpdating] = useState(false);
  const [layouts, setLayouts] = useState<API.ReportingTemplateLayoutResponse[]>(
    [],
  );
  const [newLayout, setNewLayout] =
    useState<API.ReportingTemplateLayoutResponse | null>(null);

  const { inProgress, data, error, refresh } =
    useReportingTemplate(reportingTemplateId);
  const { data: previewData } = useGenericPreviewReportingData(
    reportingTemplateId,
    3,
  );

  useEffect(() => {
    if (data?.layouts) {
      setLayouts(data.layouts);
      if (
        !selectedLayout ||
        !data.layouts.some((layout) => layout.id === selectedLayout.id)
      ) {
        setSelectedLayout(data.layouts[0]);
      }
    }
  }, [data?.layouts, selectedLayout]);

  useEffect(() => {
    if (newLayout) {
      const layoutExistsInData = data?.layouts?.some(
        (l) => l.id === newLayout.id,
      );
      if (layoutExistsInData) {
        setSelectedLayout(newLayout);
        setNewLayout(null);
      }
    }
  }, [data?.layouts, newLayout]);

  const [{ deleteModalOpen, deleteLayoutModalOpen }, setUIState] =
    useState<AssessmentState>({
      deleteModalOpen: false,
      deleteLayoutModalOpen: false,
    });

  const onDeleteComplete = useCallback(() => {
    setUIState((prevState) => ({ ...prevState, deleteModalOpen: false }));
    history.push("/reporting-templates");
  }, [history]);

  const onDeleteLayoutComplete = useCallback(() => {
    setUIState((prevState) => ({ ...prevState, deleteLayoutModalOpen: false }));
    refresh();
  }, [refresh]);

  const onLayoutUpdate = useCallback(
    async (
      layoutId: string,
      data: API.ReportingTemplateLayoutUpdateRequest,
    ) => {
      try {
        setApiUpdating(true);
        if (selectedLayout?.id === layoutId) {
          setSelectedLayout((sl) => ({
            ...sl!,
            ...data,
          }));
        }
        setLayouts((list) => {
          const result = Array.from(list);
          const index = result.findIndex((r) => r.id === layoutId);
          result[index] = { ...result[index], ...data };
          return result;
        });
        const updatedLayout = await api.updateReportingTemplateLayout(
          reportingTemplateId,
          layoutId,
          data,
        );
        setSelectedLayout(updatedLayout);
        refresh();
        setApiUpdating(false);
      } catch (e) {
        setApiUpdating(false);
        setApiError(e.message);
      }
    },
    [selectedLayout, reportingTemplateId, refresh],
  );

  const onDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destIndex = result.destination.index;

      let layoutsResult: API.ReportingTemplateLayoutResponse[] = [];

      setLayouts((list) => {
        const result = Array.from(list);
        const [removed] = result.splice(sourceIndex, 1);
        result.splice(destIndex, 0, removed);
        layoutsResult = result;
        return result;
      });

      for (let i = 0; i < layoutsResult.length; i++) {
        const val = layoutsResult[i];
        if (val.config && val.config.type === "custom_image") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_text_and_image_left") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_text_and_image_right") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_video") {
          delete val.config.videoUrl;
        }
      }

      try {
        setApiUpdating(true);
        await api.patchReportingTemplate(reportingTemplateId, {
          layouts: layoutsResult,
        });
        refresh();
        setApiUpdating(false);
      } catch (e) {
        setApiUpdating(false);
        setApiError(e.message);
      }
    },
    [refresh, reportingTemplateId],
  );

  const onCustomTemplateEdit = useCallback(
    (val: API.ReportingTemplateLayoutUpdateRequest) => {
      if (selectedLayout?.id) {
        if (val.config && val.config.type === "custom_image") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_text_and_image_left") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_text_and_image_right") {
          delete val.config.imageUrl;
        }
        if (val.config && val.config.type === "custom_video") {
          delete val.config.videoUrl;
        }
        onLayoutUpdate(selectedLayout.id, val);
      }
    },
    [onLayoutUpdate, selectedLayout?.id],
  );

  const onDeleteLayoutClick = useCallback(() => {
    setUIState((prevState) => ({
      ...prevState,
      deleteLayoutModalOpen: true,
    }));
  }, []);

  const onAddSlideFinish = useCallback(
    (newLayout: API.ReportingTemplateLayoutResponse) => {
      refresh();
      setNewLayout(newLayout);
    },
    [refresh],
  );

  return (
    <>
      {deleteModalOpen && !!data && (
        <ReportingTemplateDelete
          isOpen={deleteModalOpen}
          onClose={() =>
            setUIState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
            }))
          }
          onComplete={onDeleteComplete}
          template={data}
        />
      )}
      {deleteLayoutModalOpen && !!selectedLayout && (
        <DeleteReportingTemplateLayout
          isOpen={deleteLayoutModalOpen}
          onClose={() =>
            setUIState((prevState) => ({
              ...prevState,
              deleteLayoutModalOpen: false,
            }))
          }
          onComplete={onDeleteLayoutComplete}
          reportingTemplateId={reportingTemplateId}
          layout={selectedLayout}
        />
      )}

      <Container className="template-edit" noOverflow>
        {error && (
          <Banner type="error" active={!!error} message={error?.message} />
        )}
        {apiError != null && (
          <Banner type="error" active={!!apiError} message={apiError} />
        )}
        <InlineGroup spread verticalCenter>
          <h2>{data?.name ?? "Template Details"}</h2>
          {!!data && !!previewData && (
            <InlineGroup spread spaceBetweenElements={2}>
              <GenericPreview reportingTemplateId={data.id} />
              <AutoConfigure
                reportingTemplateId={data.id}
                onFinish={() => refresh()}
              />
              <RoleRequired roles={["superadmin", "standard", "admin"]}>
                <AddReportingTemplateLayout
                  template={data}
                  onFinish={onAddSlideFinish}
                  previewData={previewData}
                  sort={selectedLayout ? selectedLayout.sort + 1 : 0}
                />
                <CloneReportingTemplate template={data} type="button" />
              </RoleRequired>
              <IconButton
                data-test={`edit-template-${reportingTemplateId}`}
                icon="edit"
                text="Edit Presentation"
                linkTo={`/reporting-templates/${reportingTemplateId}/edit`}
              />
              <RoleRequired roles={["superadmin", "standard", "admin"]}>
                <IconButton
                  danger
                  data-test={`delete-template-${data.id}`}
                  icon="trash"
                  text="Delete"
                  onClick={() =>
                    setUIState((prevState) => ({
                      ...prevState,
                      deleteModalOpen: true,
                    }))
                  }
                />
              </RoleRequired>
            </InlineGroup>
          )}
        </InlineGroup>
        {inProgress && !data && <LoadingSpinner />}
        {!!data && !!previewData && !!selectedLayout && layouts?.length && (
          <ReportingTemplateDetailsLayout
            disabled={apiUpdating}
            droppableId={reportingTemplateId}
            onDragEnd={onDragEnd}
            layouts={layouts}
            selectedLayout={selectedLayout}
            setSelectedLayout={setSelectedLayout}
            previewData={previewData}
            onLayoutUpdate={onLayoutUpdate}
            onCustomTemplateEdit={onCustomTemplateEdit}
            onDeleteLayoutClick={onDeleteLayoutClick}
          />
        )}
      </Container>
    </>
  );
}

export default ReportingTemplateDetails;
