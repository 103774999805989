import React, { useCallback, useMemo } from "react";
import { useResultsContext } from "../context/context";
import EditableText from "../../../atoms/text/EditableText";
import ResultsText from "../results-text/ResultsText";

interface Props {
  prefix?: string;
  defaultHeading?: string;
  suffix?: string;
}

const EditableReportingHeading = ({
  prefix,
  defaultHeading,
  suffix,
}: Props) => {
  const context = useResultsContext();
  const onChange = useCallback(
    (
      val:
        | API.ReportingLayoutDefaultConfig
        | API.ReportingLayoutCustomImageConfig
        | API.ReportingLayoutCustomTextConfig
        | API.ReportingLayoutCustomVideoConfig
        | API.ReportingLayoutCustomTextAndImageLeftConfig
        | API.ReportingLayoutCustomTextAndImageRightConfig
        | API.ReportingLayoutCustomConfig,
    ) => {
      if (context.onCustomTemplateEdit) {
        context.onCustomTemplateEdit({
          config: val,
        });
      }
    },
    [context],
  );

  const config = useMemo(() => {
    return context.pages[context.pageIndex]?.config;
  }, [context.pageIndex, context.pages]);

  const heading = useMemo(() => {
    return (
      (prefix || "") + (config.heading || defaultHeading || "") + (suffix || "")
    );
  }, [config.heading, defaultHeading, suffix, prefix]);

  const editableHeading = useMemo(() => {
    return config.heading || defaultHeading || "";
  }, [config.heading, defaultHeading]);

  return (
    <>
      {context.allowCustomTemplateEdits ? (
        <EditableText
          type="text"
          value={editableHeading}
          size="7xl"
          bold
          colour="primaryDark"
          alignment="horizontal"
          formatter={(val: string) => (prefix || "") + val + (suffix || "")}
          onChange={(val: string) => {
            onChange({
              ...config,
              heading: val,
            });
          }}
        />
      ) : (
        <ResultsText size="2xl" bold colour="primaryDark">
          {heading}
        </ResultsText>
      )}
    </>
  );
};

export default EditableReportingHeading;
