import React from "react";
import usePreviewReportingData from "../../../../hooks/usePreviewReportingData";

interface Props {
  reportingTemplateId: string;
  simulationId: string;
  brandingId?: string;
  round: number;
  children(response: {
    inProgress: boolean;
    error: Error | null;
    data: ModelAPI.Reporting.ReportingResults | null;
  }): React.ReactNode;
}

const ReportingTemplatePreviewContentFetcher: React.FC<Props> = ({
  reportingTemplateId,
  simulationId,
  brandingId,
  round,
  children,
}) => {
  const { inProgress, error, data } = usePreviewReportingData(
    reportingTemplateId,
    simulationId,
    round,
    brandingId,
  );

  return <>{children({ inProgress, error, data })}</>;
};

export default ReportingTemplatePreviewContentFetcher;
