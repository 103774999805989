import React, { useCallback } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import { useResultsContext } from "../context/context";
import ResultsTable from "../table/ResultsTable";
import { Td, Th } from "../../../atoms/table/Table";
import Image from "../../../atoms/image/Image";
import teamsImage from "../../../../assets/results/teams.png";
import "./RoundResultsParticipantSummary.scss";
import EditableReportingHeading from "../components/EditableReportingHeading";

interface Props {
  participants: ReportingParticipant[];
}
interface ReportingParticipant {
  team: number;
  firstName: string;
  lastName: string;
}

interface TeamParticipant {
  firstName: string;
  lastName: string;
}

interface TeamParticipants {
  [key: string]: TeamParticipant[];
}

const RoundResultsParticipantSummary: React.FC<Props> = ({ participants }) => {
  const context = useResultsContext();

  const teams = participants.reduce<TeamParticipants>((acc, participant) => {
    const { team, firstName, lastName } = participant;
    acc[team] = acc[team] || [];
    acc[team].push({ firstName, lastName });
    return acc;
  }, {});
  const maxParticipantsAllTeams = Math.max(
    ...Object.values(teams).map((participants) => participants.length),
    0,
  );

  const TEAMS_PER_PAGE = maxParticipantsAllTeams > 15 ? 3 : 6;

  const totalPages = Math.ceil(Object.keys(teams).length / TEAMS_PER_PAGE);
  const currentTeamsKeys = Object.keys(teams).slice(
    context.clicks * TEAMS_PER_PAGE,
    (context.clicks + 1) * TEAMS_PER_PAGE,
  );

  const currentTeamsWithPlaceholders = [...currentTeamsKeys];
  if (Object.keys(teams).length >= TEAMS_PER_PAGE) {
    while (currentTeamsWithPlaceholders.length < TEAMS_PER_PAGE) {
      currentTeamsWithPlaceholders.push(
        `placeholder-${currentTeamsWithPlaceholders.length}`,
      );
    }
  }

  const onNext = useCallback(() => {
    if (context.clicks < totalPages - 1) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [totalPages, context]);
  const gridColumns = Math.ceil(Math.sqrt(currentTeamsWithPlaceholders.length));

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full wide center spaceBetweenElements={2}>
        <EditableReportingHeading defaultHeading="Participant Summary" />
        <InlineGroup
          fullHeight
          verticalCenter
          center
          block
          spaceBetweenElements={10}
        >
          <VerticalGroup center wide full>
            <VerticalGroup full center verticalCenter>
              <div className={`teamGrid grid-columns-${gridColumns}`}>
                {currentTeamsWithPlaceholders.map((teamKey) => {
                  const isPlaceholder = teamKey.startsWith("placeholder");
                  if (isPlaceholder) {
                    return (
                      <VerticalGroup key={teamKey} center>
                        <ResultsTable
                          className={`team-table-placeholder-${teamKey}`}
                        >
                          <thead>
                            <tr>
                              <Th>
                                <ResultsText size="xs">{"\u00A0"}</ResultsText>
                              </Th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.from(
                              { length: maxParticipantsAllTeams },
                              (_, i) => (
                                <tr key={`placeholder-row-${i}`}>
                                  <Td>
                                    <InlineGroup
                                      center
                                      spaceBetweenElements={3}
                                    >
                                      <ResultsText size="xs">
                                        {"\u00A0"}
                                      </ResultsText>
                                    </InlineGroup>
                                  </Td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </ResultsTable>
                      </VerticalGroup>
                    );
                  } else {
                    const participants = teams[teamKey];
                    return (
                      <VerticalGroup key={teamKey} center>
                        <ResultsTable
                          className={`team-table-${teamKey} grid-columns-${gridColumns} team-table`}
                        >
                          <thead>
                            <tr>
                              <Th>
                                <ResultsText
                                  center
                                  colour="blue"
                                  bold
                                  size="xs"
                                >
                                  Team {teamKey}
                                </ResultsText>
                              </Th>
                            </tr>
                          </thead>
                          <tbody>
                            {participants.map((participant, index) => (
                              <tr key={index}>
                                <Td>
                                  <InlineGroup center spaceBetweenElements={3}>
                                    <ResultsText size="xs">
                                      {participant.firstName}
                                    </ResultsText>
                                    <ResultsText size="xs">
                                      {participant.lastName}
                                    </ResultsText>
                                  </InlineGroup>
                                </Td>
                              </tr>
                            ))}
                            {Array.from(
                              {
                                length:
                                  maxParticipantsAllTeams - participants.length,
                              },
                              (_, i) => (
                                <tr key={`empty-${i}`}>
                                  <Td>
                                    <InlineGroup
                                      center
                                      spaceBetweenElements={3}
                                    >
                                      <ResultsText size="xs">
                                        {"\u00A0"}
                                      </ResultsText>
                                    </InlineGroup>
                                  </Td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </ResultsTable>
                      </VerticalGroup>
                    );
                  }
                })}
              </div>
            </VerticalGroup>
          </VerticalGroup>
          <Image
            src={teamsImage}
            alt="Teams"
            dynamicSize={{ min: "150px", preferred: "30vw", max: "1000px" }}
          />
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsParticipantSummary;
