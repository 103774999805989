import React, { useCallback, useState } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Card from "../../../atoms/card/Card";
import { FileDropZone } from "../../../organisms/file-dropzone/FileDropZone";

import "../RoundResultsTablePage.scss";
import { useResultsContext } from "../context/context";
import API from "../../../../services/api";
import InlineError from "../../../atoms/inlineerror/InlineError";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import Video from "../../../atoms/video/Video";
import Image from "../../../atoms/image/Image";
import EditableReportingHeading from "../components/EditableReportingHeading";
import ResultsText from "../results-text/ResultsText";
import Icon from "../../../atoms/icon/Icon";
import Crypto from "crypto-js";

interface Props {
  data: API.ReportingLayoutCustomVideoConfig;
}

const RoundResultsCustomVideo: React.FC<Props> = ({ data }) => {
  const context = useResultsContext();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setUploading] = useState(false);
  const onChange = useCallback(
    (val: API.ReportingLayoutCustomVideoConfig) => {
      if (context.onCustomTemplateEdit) {
        context.onCustomTemplateEdit({
          config: val,
        });
      }
    },
    [context],
  );

  const onDrop = useCallback(
    (files: File[]) => {
      (async () => {
        setUploading(true);
        setUploadError(null);

        const newFile = files[0];

        // const newFile = await toWebpBlobs(files[0])
        // if (newFile && newFile.size > 6000000) {
        //   setUploadError('File size must <= 10M, please try again with a smaller video')
        //   setUploading(false)
        //   return
        // }

        if (newFile) {
          const reader = new FileReader();

          reader.onload = async (event: any) => {
            try {
              const binary = event.target.result;
              const wordArray = Crypto.lib.WordArray.create(binary);
              const md5Hash = Crypto.MD5(wordArray).toString();

              const filename = `${md5Hash}.${newFile.name.split(".").pop()}`;

              const { url, video } = await API.getReportingVideoUrl(
                filename,
                md5Hash,
              );

              const s3response = await API.uploadReportingVideoToS3(
                newFile,
                url,
              );

              if (s3response.status !== 200) {
                setUploading(false);
                setUploadError(
                  `something went wrong when uploading file ${JSON.stringify(s3response.data)}`,
                );
                return;
              }
              onChange({
                ...data,
                videoId: video.id,
              });
              setUploading(false);
              setUploadError(null);
            } catch (e) {
              setUploading(false);
              setUploadError("something went wrong when uploading file");
            }
          };

          reader.readAsArrayBuffer(newFile);
        }
      })();
    },
    [data, onChange],
  );

  return (
    <RoundResultsContainer>
      <VerticalGroup full center wide>
        <EditableReportingHeading />
        <InlineGroup fullHeight block>
          <Card wide sharp padding={2}>
            <InlineGroup fullHeight block center>
              {data.videoUrl && !context.noVideo && (
                <InlineGroup className="video-container">
                  <Video
                    key={data.videoId}
                    src={data.videoUrl}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    setAutoPlay={!context.noAnimations}
                    muted={context.noAudio}
                  />
                </InlineGroup>
              )}
              {data.videoUrl && context.noVideo && (
                <VerticalGroup wide full center verticalCenter>
                  <InlineGroup verticalCenter>
                    <Icon type="play" size={10} />
                    <ResultsText>Now Playing on main screen</ResultsText>
                  </InlineGroup>
                </VerticalGroup>
              )}
              {!data.videoUrl &&
                !data.videoId &&
                !context.allowCustomTemplateEdits && (
                  <InlineGroup fullHeight center verticalCenter block>
                    <Image
                      src="https://via.placeholder.com/728x500.png?text=Add+Your+Custom+Video"
                      alt="Custom Video"
                      contain
                      dynamicSize={{
                        min: "400px",
                        preferred: "85vw",
                        max: "1500px",
                      }}
                    />
                  </InlineGroup>
                )}
              {!data.videoId &&
                !data.videoUrl &&
                context.allowCustomTemplateEdits && (
                  <VerticalGroup wide full>
                    <FileDropZone
                      required={false}
                      className="width-100-percent"
                      onDrop={onDrop}
                      accept="video/*"
                      dropzonePlaceholderMain="Drag your file here"
                      dropzonePlaceholderSecondary="or click to upload from your computer"
                      isLoading={isUploading}
                      fullHeight
                    />
                    {uploadError && (
                      <InlineError active message={uploadError} />
                    )}
                  </VerticalGroup>
                )}
            </InlineGroup>
          </Card>
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCustomVideo;
