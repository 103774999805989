import React, {
  forwardRef,
  useCallback,
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import classNames from "classnames";
import ContentEditable from "react-contenteditable";
import * as linkify from "linkifyjs";
import sanitizeHtml from "sanitize-html";
import "./Input.scss";
import InputContainer from "./InputContainer";
import ContextMenu from "../../../organisms/contextMenu/ContextMenu";

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement>,
    React.ComponentProps<typeof InputContainer> {
  allowLastPass?: boolean;
  inputClassName?: string;
  onFieldInvalid?: (errorMessage: string | undefined) => void;
  enableRightClickMenu?: boolean;
  dropdownOptions?: Option[];
}

export interface Option {
  label: string;
  id: string | number;
}

export const Textbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      inputClassName,
      error,
      block,
      large,
      small,
      onChange,
      name,
      id,
      onFieldInvalid,
      enableRightClickMenu,
      dropdownOptions = [],
      ...rest
    },
    ref,
  ) => {
    Textbox.displayName = "Textbox";
    const [cursorPosition, setCursorPosition] = useState<Range | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const contentEditableRef = useRef<HTMLDivElement>(null);
    const contextMenuRef = useRef<HTMLUListElement>(null);
    const [value, setValue] = useState<string>("");
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<{
      top: number;
      left: number;
    }>({ top: 0, left: 0 });
    const handleContextMenuSelect = (option: Option) => {
      if (cursorPosition && contentEditableRef.current) {
        const range = cursorPosition.cloneRange();

        const textNode = document.createTextNode(option.label);
        range.insertNode(textNode);

        range.collapse(false);

        const selection = window.getSelection();
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
        }

        const newValue = contentEditableRef.current.innerHTML;
        setValue(newValue);

        setShowContextMenu(false);
        setCursorPosition(null);
      }
    };
    const handleRightClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();

      const selection = window.getSelection();
      if (selection && selection.rangeCount > 0) {
        setCursorPosition(selection.getRangeAt(0));
      }

      setShowContextMenu(true);
      setContextMenuPosition({ top: e.clientY, left: e.clientX });
    };

    const handleDropdownClose = () => {
      setShowContextMenu(false);
    };

    const onTextBoxPaste = useCallback(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        const data = e.clipboardData.getData("text/html");

        const html = sanitizeHtml(data, {
          allowedTags: ["div", "br", "li", "ol", "ul", "p", "b"],
          allowedAttributes: {},
        });

        const strippedHtml = html.replace(/\r\n/g, "");

        if (strippedHtml.length) {
          e.preventDefault();
          document.execCommand("insertHTML", false, strippedHtml);
        }
      },
      [],
    );

    const onTextBoxChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);

        const html = sanitizeHtml(e.target.value, {
          allowedTags: ["div", "br", "li", "ol", "ul", "p", "b"],
          allowedAttributes: {},
        });

        const links = linkify.find(e.target.value);

        e.target.value = html;
        onChange && onChange(e);

        if (inputRef.current) {
          onFieldInvalid &&
            onFieldInvalid(
              links.length > 0 ? "URLs are not allowed in the text" : undefined,
            );
        }
      },
      [onChange, onFieldInvalid],
    );

    useLayoutEffect(() => {
      if (typeof ref === "function") {
        ref(inputRef.current);
      }
    }, [ref]);

    useEffect(() => {
      setValue(String(rest.value));
    }, [rest.value]);

    useEffect(() => {
      const handleClickOutsideMenu = (event: MouseEvent) => {
        if (
          contextMenuRef.current &&
          !contextMenuRef.current.contains(event.target as Node)
        ) {
          setShowContextMenu(false);
        }
      };

      if (showContextMenu) {
        document.addEventListener("click", handleClickOutsideMenu);
      }

      return () => {
        document.removeEventListener("click", handleClickOutsideMenu);
      };
    }, [showContextMenu, contextMenuRef]);

    useEffect(() => {
      if (contentEditableRef.current) {
        contentEditableRef.current.focus();
      } else if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);
    return (
      <>
        <ContentEditable
          innerRef={contentEditableRef}
          role="textbox"
          className={classNames("input", inputClassName, {
            invalid: error,
            block,
            large,
            small,
          })}
          html={value}
          {...rest}
          onChange={onTextBoxChange as any}
          onPaste={onTextBoxPaste}
          onContextMenu={enableRightClickMenu ? handleRightClick : undefined}
        />
        <input
          {...rest}
          type="hidden"
          id={id}
          name={name}
          ref={inputRef}
          value={rest.value}
        />
        {showContextMenu && (
          <ContextMenu
            show={showContextMenu}
            position={contextMenuPosition}
            options={dropdownOptions}
            onSelect={handleContextMenuSelect}
          />
        )}

        <div onClick={handleDropdownClose} />
      </>
    );
  },
);
