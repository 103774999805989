import React, { useState, useCallback, useEffect } from "react";
import API from "../../../../../../services/api";
import useAPIRequest from "../../../../../../hooks/useAPIRequest";
import DropModal from "../../../../../organisms/drop-modal/DropModal";
import SuccessModal from "../../../../../organisms/standard-modal/SuccessModal";
import ErrorModal from "../../../../../organisms/standard-modal/ErrorModal";
import { pluralise } from "../../../../../../lib/pluralise";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  eventId: string;
}

function ParticipantImport({ isOpen, onClose, onComplete, eventId }: Props) {
  const [file, setFile] = useState<File | null>();
  const [errorMsg, setErrorMsg] = useState<string | null>();
  const [showResult, setShowResult] = useState(false);

  const callback = useCallback(() => {
    return API.uploadParticipants(eventId, file!);
  }, [eventId, file]);

  const [{ data, inProgress, error }, doApiRequest] = useAPIRequest(
    callback,
    {},
  );

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const handleConfirm = useCallback(() => {
    file
      ? doApiRequest(() => setShowResult(true))
      : setErrorMsg("Please upload participant sheet first"); //
  }, [doApiRequest, file]);

  useEffect(() => {
    if (!error) return;
    if (error.message !== errorMsg) {
      setErrorMsg(error.message);
    }
  }, [error, errorMsg]);

  const handleDownload = () => {
    if (eventId) API.downloadParticipantsTemplate(eventId);
  };

  if (showResult) {
    const successfulCount = data?.participants.length ?? 0;
    const resultMessage = (
      <>
        {successfulCount > 0 && (
          <p>{`${successfulCount} participant${successfulCount ? "s" : ""} were successfully imported`}</p>
        )}
        {!!data?.failed && (
          <p>
            Errors occurred: {data?.failed}{" "}
            {pluralise("participant", data.failed)}{" "}
            {pluralise("is", data.failed, "are")} not valid. Please fix{" "}
            {pluralise("this issue", data.failed, "these issues")} and
            re-import.
          </p>
        )}
        {!!data?.failureReasons && (
          <p>
            {data.failureReasons.map((reason, i) => (
              <div key={i}>{reason}</div>
            ))}
          </p>
        )}
      </>
    );
    if (data?.failed) {
      return (
        <ErrorModal
          isOpen
          onClose={onComplete}
          title="Import Summary"
          description={resultMessage}
        />
      );
    }
    return (
      <SuccessModal
        isOpen
        onClose={onComplete}
        title="Import Summary"
        description={resultMessage}
      />
    );
  }

  return (
    <DropModal
      title="Import Participants"
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      confirmTitle="Import"
      error={errorMsg || null}
      errorActive={!file || !!error}
      dropzoneText={file?.name}
      dropzonePlaceholderMain="Drag your file here"
      dropzonePlaceholderSecondary="or click to upload from your computer"
      onDrop={onDrop}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      downloadMsg="Download participant template here"
      downloadOnClick={handleDownload}
    />
  );
}

export default ParticipantImport;
