import React, { useCallback } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../atoms/text/Text";
import PresentResultsRndDropdown from "../../game/PresentResultsRndDropdown";

interface Props {
  roundNumber: number;
  handleRoundUpdate?: (round: number) => void;
}

const RoundResultsNotGeneratedError: React.FC<Props> = ({
  roundNumber,
  handleRoundUpdate,
}) => {
  const onRoundNumberChange = useCallback(
    (round: number) => {
      handleRoundUpdate && handleRoundUpdate(round);
    },
    [handleRoundUpdate],
  );

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div className="results-container results-container-summary">
        <div className="results" style={{ flex: 1 }}>
          <VerticalGroup
            wide
            id="round-results-container"
            center
            verticalCenter
            full
          >
            <div>
              <Text colour="white" bold size="3xl">
                Round {roundNumber} results have not been generated yet.
              </Text>
              <p>
                <Text colour="white">
                  Please wait for the results to be generated before viewing
                  them.
                </Text>
              </p>
              {handleRoundUpdate && (
                <PresentResultsRndDropdown
                  currentRound={roundNumber}
                  maxRound={6}
                  onChange={onRoundNumberChange}
                />
              )}
            </div>
          </VerticalGroup>
        </div>
      </div>
    </div>
  );
};

export default RoundResultsNotGeneratedError;
