import React, { useCallback, useMemo } from "react";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import RoundResultsSummary from "./slides/RoundResultsSummary";
import RoundResultsMarketDebrief from "./slides/RoundResultsMarketDebrief";
import RoundResultsCustomerSatisfaction from "./slides/RoundResultsCustomerSatisfaction";
import RoundResultsTotalShareholderReturn from "./slides/RoundResultsTotalShareholderReturn";
import RoundResultsReturnOnEquity from "./slides/RoundResultsReturnOnEquity";
import RoundResultsCustomers from "./slides/RoundResultsCustomers";
import RoundResultsCreditRating from "./slides/RoundResultsCreditRating";
import RoundResultsLoanImpairmentExpense from "./slides/RoundResultsLoanImpairmentExpense";
import RoundResultsEmployeeEngagement from "./slides/RoundResultsEmployeeEngagement";
import RoundResultsCustomerComplaints from "./slides/RoundResultsCustomerComplaints";
import RoundResultsReputationIndex from "./slides/RoundResultsReputationIndex";
import RoundResultsRiskIncidents from "./slides/RoundResultsRiskIncidents";
import RoundResultsRegulatorSanctions from "./slides/RoundResultsRegulatorSanctions";
import RoundResultsIncrementalFTE from "./slides/RoundResultsIncrementalFTE";
import RoundResultsIncrementalFTECosts from "./slides/RoundResultsIncrementalFTECosts";
import RoundResultsBusinessLoanLosses from "./slides/RoundResultsBusinessLoanLosses";
import RoundResultsOverallWinningMetric from "./slides/RoundResultsOverallWinningMetric";
import RoundResultsWholesaleFundingIssues from "./slides/RoundResultsWholesaleFundingIssues";
import RoundResultsNPATRoe from "./slides/RoundResultsNPATRoe";
import RoundResultsExecutiveDecisionsTable from "./slides/RoundResultsExecutiveDecisionsTable";
import RoundResultsDealRoomTable from "./slides/RoundResultsDealRoomTable";
import RoundResultsBankBailOuts from "./slides/RoundResultsBankBailOuts";
import RoundResultsRegulatoryCapitalInterventions from "./slides/RoundResultsRegulatoryCapitalIntervention";
import RoundResultsRiskIncidentsClosing from "./slides/RoundResultsRiskIncidentsClosing";
import RoundResultsRoyalCommission from "./slides/RoundResultsRoyalCommission";
import RoundResultsNeoBankAmlIssue from "./slides/RoundResultsNeoBankAmlIssue";
import RoundResultsNewsRoom from "./slides/RoundResultsNewsRoom";
import RoundResultsDealRoomInformation from "./slides/RoundResultsDealRoomInformation";
import RoundResultsMarketMargins from "./slides/RoundResultsMarketMargins";
import RoundResultsCustomText from "./slides/RoundResultsCustomText";
import RoundResultsCustomImage from "./slides/RoundResultsCustomImage";
import { fakeRiskIncidentsClosing } from "./fakeData";
import Theme from "../../../styles/_theme.module.scss";
import ResultsHeader from "./header/ResultsHeader";
import RoundResultsTeamSummary from "./slides/RoundResultsTeamSummary";
import RoundResultsCeoTransfer from "./slides/RoundResultsCeoTransfer";
import RoundResultsReversion from "./slides/RoundResultsReversion";
import RoundResultsSurvey from "./slides/RoundResultsSurvey";
import RoundResultsThankYou from "./slides/RoundResultsThankYou";
import RoundResultsExcoInformation from "./slides/RoundResultsExcoInformation";
import RoundResultsPackUp from "./slides/RoundResultsPackUp";
import RoundResultsLetsGo from "./slides/RoundResultsLetsGo";
import RoundResultsWholesaleFundingCosts from "./slides/RoundResultsWholesaleFundingCosts";
import RoundResultsCustomVideo from "./slides/RoundResultsCustomVideo";
import RoundResultsParticipantSummary from "./slides/RoundResultsParticipantSummary";
import { useResultsContext } from "./context/context";
import RoundResultsCustomImageAndText from "./slides/RoundResultsCustomImageAndText";
import RoundResultsKeyOutcomes from "./slides/RoundResultsKeyOutcomes";
import RoundResultsWellRunBanks from "./slides/RoundResultsWellRunBanks";
import RoundResultsContainer from "./components/RoundsResultsContainer";
import RoundResultsPoorRisk from "./slides/RoundResultsPoorRisk/RoundResultsPoorRisk";
import RoundResultsCustomLayoutBuilder from "./slides/RoundResultsCustomLayoutBuilder/RoundResultsCustomLayoutBuilder";
import DebugInfoModal from "./components/DebugInfoModal";

const colours = [
  Theme.chart0,
  Theme.chart1,
  Theme.chart2,
  Theme.chart3,
  Theme.chart4,
  Theme.chart5,
  Theme.chart6,
  Theme.chart7,
  Theme.chart8,
  Theme.chart9,
];

const cpuColours = [Theme.pc1, Theme.pc2, Theme.pc3];

export interface TeamsWithColours extends ModelAPI.Reporting.ReportingTeams {
  colour: string;
}

interface Props {
  data: ModelAPI.Reporting.ReportingResults;
}

const RoundResultsLayout: React.FC<Props> = ({ data }: Props) => {
  const context = useResultsContext();

  const handleDebugCloseModal = useCallback(() => {
    context.updateShowDebug(false);
  }, [context]);

  const pastRound1 = context.roundNumber > 1;

  const teams: TeamsWithColours[] = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.teams.map((team, idx) => {
      return {
        ...team,
        colour: team.isCpuTeam
          ? cpuColours[idx % cpuColours.length]
          : colours[idx % colours.length],
      };
    });
  }, [data]);

  const currentPage = useMemo(() => {
    return context.pages[context.pageIndex];
  }, [context.pageIndex, context.pages]);

  if (currentPage?.type === "summary") {
    return (
      <div className="results-container results-container-summary">
        <div className="results" style={{ flex: 1 }}>
          <VerticalGroup full center key={"render" + context.renderKey}>
            <VerticalGroup wide full className="">
              <RoundResultsContainer withoutPadding>
                <RoundResultsSummary
                  logo={data.logo}
                  gameName={data.gameName}
                  round={context.roundNumber}
                />
              </RoundResultsContainer>
            </VerticalGroup>
          </VerticalGroup>
        </div>
      </div>
    );
  }
  return (
    <div
      className="results-container results-gradient"
      style={{
        flex: 1,
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {!context.hideHeader && (
        <ResultsHeader
          logo={data.logo}
          gameName={data?.gameName ?? ""}
          round={context.roundNumber}
        />
      )}
      <div
        style={{
          flex: 1,
          justifyContent: "space-between",
          flexDirection: "column",
          display: "flex",
          height: "90%",
        }}
        key={"render" + context.renderKey}
      >
        {currentPage?.type === "market_debrief" && (
          <RoundResultsMarketDebrief data={data.marketDebrief} />
        )}
        {currentPage?.type === "customer_satisfaction" && (
          <RoundResultsCustomerSatisfaction
            pastRound1={pastRound1}
            teams={teams}
            customerSatisfaction={data.customerSatisfaction}
          />
        )}
        {currentPage?.type === "total_shareholder_return" && (
          <RoundResultsTotalShareholderReturn
            pastRound1={pastRound1}
            teams={teams}
            shareholderReturn={data.shareholderReturn}
          />
        )}
        {currentPage?.type === "return_on_equity" && (
          <RoundResultsReturnOnEquity
            pastRound1={pastRound1}
            teams={teams}
            returnOnEquity={data.returnOnEquity}
          />
        )}
        {currentPage?.type === "customers" && (
          <RoundResultsCustomers
            pastRound1={pastRound1}
            teams={teams}
            customers={data.customers}
          />
        )}
        {currentPage?.type === "credit_rating" && (
          <RoundResultsCreditRating
            pastRound1={pastRound1}
            teams={teams}
            creditRating={data.creditRating}
          />
        )}
        {currentPage?.type === "loan_impairment_expense" && (
          <RoundResultsLoanImpairmentExpense
            pastRound1={pastRound1}
            teams={teams}
            loanImpairmentExpense={data.loanImpairmentExpense}
            creditSettings={data.creditSettings}
          />
        )}
        {currentPage?.type === "employee_engagement" && (
          <RoundResultsEmployeeEngagement
            pastRound1={pastRound1}
            teams={teams}
            employeeEngagement={data.employeeEngagement}
          />
        )}
        {currentPage?.type === "customer_complaints" && (
          <RoundResultsCustomerComplaints
            pastRound1={pastRound1}
            teams={teams}
            customerComplaints={data.customerComplaints}
          />
        )}
        {currentPage?.type === "reputation_index" && (
          <RoundResultsReputationIndex
            pastRound1={pastRound1}
            teams={teams}
            repuationIndex={data.reputationIndex}
          />
        )}
        {currentPage?.type === "risk_incidents" && (
          <RoundResultsRiskIncidents
            pastRound1={pastRound1}
            teams={teams}
            riskIncidents={data.riskIncidents}
          />
        )}
        {currentPage?.type === "regulator_sanctions" && (
          <RoundResultsRegulatorSanctions
            pastRound1={pastRound1}
            teams={teams}
            regulatorSanctions={data.regulatorSanctions}
          />
        )}
        {currentPage?.type === "incremental_fte" && (
          <RoundResultsIncrementalFTE
            pastRound1={pastRound1}
            teams={teams}
            incrementalFTE={data.incrementalFTE}
          />
        )}
        {currentPage?.type === "incremental_fte_costs" && (
          <RoundResultsIncrementalFTECosts
            pastRound1={pastRound1}
            currencyDenomination={data.currencyDenomination}
            teams={teams}
            incrementalFTECosts={data.incrementalFTECosts}
            key={context.renderKey}
            parentKey={context.renderKey}
          />
        )}
        {currentPage?.type === "business_loan_losses" && (
          <RoundResultsBusinessLoanLosses
            pastRound1={pastRound1}
            teams={teams}
            businessLoanLosses={data.businessLoanLosses}
            creditSettings={data.creditSettings}
            currencyDenomination={data.currencyDenomination}
          />
        )}
        {currentPage?.type === "well_run_banks" && <RoundResultsWellRunBanks />}
        {currentPage?.type === "overall_winning_metric" && (
          <RoundResultsOverallWinningMetric
            pastRound1={pastRound1}
            teams={teams}
            overallWinningMetric={data.overallWinner}
          />
        )}
        {currentPage?.type === "wholesale_funding_costs" && (
          <RoundResultsWholesaleFundingCosts
            pastRound1={pastRound1}
            wholesaleFundingCostsAllRatings={data.wsfCostsAllRatings}
            wholesaleFundingCostsARatings={data.wsfCostsARatings}
          />
        )}
        {currentPage?.type === "wholesale_funding_issues" && (
          <RoundResultsWholesaleFundingIssues
            currencyDenomination={data.currencyDenomination}
            teams={teams}
            wholesaleFundingIssues={data.wholesaleFundingIssues}
          />
        )}
        {currentPage?.type === "npat_&_roe" && (
          <RoundResultsNPATRoe
            currencyDenomination={data.currencyDenomination}
            teams={teams}
            npatRoe={data.npatAndRoe}
          />
        )}
        {currentPage?.type === "executive_decisions_table" && (
          <RoundResultsExecutiveDecisionsTable
            executiveDecisions={data.excoDecision!}
            teams={teams}
          />
        )}
        {currentPage?.type === "deal_room_results" && (
          <RoundResultsDealRoomTable dealRoom={data.dealRoom!} teams={teams} />
        )}
        {currentPage?.type === "bank_bail_outs" && (
          <RoundResultsBankBailOuts data={data.bankBailouts} teams={teams} />
        )}
        {currentPage?.type === "regulatory_capital_intervention" && (
          <RoundResultsRegulatoryCapitalInterventions
            data={data.regulatoryCapitalIntervention}
            teams={teams}
          />
        )}
        {currentPage?.type === "risk_incidents_closing" && (
          <RoundResultsRiskIncidentsClosing
            riskIncidentsClosing={fakeRiskIncidentsClosing}
            teams={teams}
            currencyDenomination={data.currencyDenomination}
          />
        )}
        {currentPage?.type === "royal_commission" && (
          <RoundResultsRoyalCommission
            teams={teams}
            currencyDenomination={data.currencyDenomination}
            data={data.royalCommissionEvent!}
          />
        )}
        {currentPage?.type === "neobank_aml_issue" && (
          <RoundResultsNeoBankAmlIssue
            teams={teams}
            amlEvent={data.amlEvent!}
            currencyDenomination={data.currencyDenomination}
          />
        )}
        {currentPage?.type === "news_room" && (
          <RoundResultsNewsRoom
            round={String(context.roundNumber + 1)}
            data={data.newsRoom!}
          />
        )}
        {currentPage?.type === "deal_room_information" && (
          <RoundResultsDealRoomInformation
            data={data.nextDealRoom!}
            config={currentPage.config}
          />
        )}
        {currentPage?.type === "net_interest_margin" && (
          <RoundResultsMarketMargins
            pastRound1={pastRound1}
            teams={teams}
            marketMargins={data.marketMargins}
          />
        )}
        {currentPage?.type === "custom_text" &&
          currentPage.config.type === "custom_text" && (
            <RoundResultsCustomText data={currentPage?.config} />
          )}
        {currentPage?.type === "custom_image" &&
          currentPage.config.type === "custom_image" && (
            <RoundResultsCustomImage data={currentPage?.config} />
          )}
        {currentPage?.type === "custom_text_and_image_left" &&
          currentPage.config.type === "custom_text_and_image_left" && (
            <RoundResultsCustomImageAndText
              data={currentPage?.config}
              imageAlignment="left"
            />
          )}
        {currentPage?.type === "custom_text_and_image_right" &&
          currentPage.config.type === "custom_text_and_image_right" && (
            <RoundResultsCustomImageAndText
              data={currentPage?.config}
              imageAlignment="right"
            />
          )}
        {currentPage?.type === "custom_video" &&
          currentPage.config.type === "custom_video" && (
            <RoundResultsCustomVideo data={currentPage?.config} />
          )}

        {currentPage?.type === "custom_slide" &&
          currentPage.config.type === "custom_slide" && (
            <RoundResultsCustomLayoutBuilder data={currentPage.config} />
          )}

        {currentPage?.type === "team_summary" && (
          <RoundResultsTeamSummary data={teams} />
        )}
        {currentPage?.type === "participant_summary" && (
          <RoundResultsParticipantSummary
            // data={teams}
            participants={data.participants}
          />
        )}
        {currentPage?.type === "ceo_transfer" && (
          <RoundResultsCeoTransfer simulationType={data.simulationType} />
        )}
        {currentPage?.type === "reversion" && (
          <RoundResultsReversion simConfig={data.simConfig} />
        )}
        {currentPage?.type === "survey" && <RoundResultsSurvey />}
        {currentPage?.type === "key_outcomes" && <RoundResultsKeyOutcomes />}
        {currentPage?.type === "thank_you" && <RoundResultsThankYou />}
        {currentPage?.type === "exco_information" && (
          <RoundResultsExcoInformation config={currentPage.config} />
        )}
        {currentPage?.type === "lets_go" && <RoundResultsLetsGo />}
        {currentPage?.type === "pack_up" && <RoundResultsPackUp />}
        {currentPage?.type === "poor_risk_management" && (
          <RoundResultsPoorRisk />
        )}
        {context.showDebug && (
          <DebugInfoModal onClose={handleDebugCloseModal} />
        )}
      </div>
    </div>
  );
};

export default RoundResultsLayout;
