import React, { useMemo, useCallback } from "react";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import Image from "../../../atoms/image/Image";
import ArcherMan from "../../../../assets/results/ArcherMan.png";
import Law from "../../../../assets/results/law.png";
import ResultsTable from "../table/ResultsTable";
import { TeamsWithColours } from "../RoundResultsPage";
import { round, thousandSeparator } from "../../model/utils/formatters";

import "../RoundResultsTablePage.scss";
import { useResultsContext } from "../context/context";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";

interface Props {
  teams: TeamsWithColours[];
  data: ModelAPI.Reporting.TeamAndAmount[];
}

const getSortedCapitalFromHighestToLowest = (
  data: ModelAPI.Reporting.TeamAndAmount[],
) => {
  return data.filter((a) => a.amount > 0).sort((a, b) => b.amount - a.amount);
};

export const getMaxClicks = (data: ModelAPI.Reporting.TeamAndAmount[]) => {
  return getSortedCapitalFromHighestToLowest(data).length;
};

const RoundResultsRegulatoryCapitalInterventions: React.FC<Props> = ({
  data,
  teams,
}) => {
  const context = useResultsContext();
  const { sortedCapitalFromHighestToLowest } = useMemo(() => {
    const sortedCapitalFromHighestToLowest =
      getSortedCapitalFromHighestToLowest(data);
    return { sortedCapitalFromHighestToLowest };
  }, [data]);

  const maxClicks = getMaxClicks(data);

  const showRow = useMemo(() => {
    return context.noAnimations ? 0 : maxClicks - context.clicks;
  }, [context.clicks, context.noAnimations, maxClicks]);

  const onNext = useCallback(() => {
    if (!context.hidePagination) {
      context.goNextPage();
      return;
    }
    if (showRow > 0) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context, showRow]);

  return (
    <RoundResultsContainer onNext={onNext}>
      {sortedCapitalFromHighestToLowest.length === 0 ? (
        <VerticalGroup full center wide>
          <ResultsText size="2xl" bold className="mb-2" colour="primaryDark">
            Great Capital Management
          </ResultsText>
          <ResultsText size="xl" bold colour="blue">
            No Bank&apos;s required Regulator Capital Intervention
          </ResultsText>
          <InlineGroup fullHeight center verticalCenter>
            <Image
              src={ArcherMan}
              alt="Archor Man"
              dynamicSize={{ min: "250px", preferred: "22vw", max: "980px" }}
            ></Image>
          </InlineGroup>
        </VerticalGroup>
      ) : (
        <VerticalGroup full center wide spaceBetweenElements={2}>
          <EditableReportingHeading defaultHeading="Regulatory Capital Intervention" />
          <ResultsText size="xl" bold colour="blue" className="mb-2">
            Insufficiently Capitalised Banks
          </ResultsText>
          <InlineGroup spread fullHeight verticalCenter>
            <VerticalGroup center className="mr-10">
              <Icon
                type="slide"
                dynamicSize={{ min: "130px", preferred: "15vw", max: "900px" }}
                colour="blue"
              ></Icon>
            </VerticalGroup>
            <VerticalGroup style={{ width: "100%", height: "90%" }}>
              <ResultsTable id="999">
                <thead>
                  <tr>
                    <th>BANK</th>
                    <th>AMOUNT ($m)</th>
                  </tr>
                </thead>
                <tbody onClick={() => context.addClick()} className="clickable">
                  {sortedCapitalFromHighestToLowest.map((cap, i) => {
                    const teamForTable = teams.find(
                      (t) => t.teamId === cap.teamId,
                    );

                    return (
                      <tr
                        key={i}
                        className={
                          showRow <= i ? "show-bids" : "no-show-options"
                        }
                      >
                        <td style={{ color: `${teamForTable?.colour}` }}>
                          {teamForTable?.teamName}
                        </td>
                        <td>{thousandSeparator(round(cap.amount, 0))}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </ResultsTable>
              {/* Added so you know to click on table body to display data */}
              {!context.hidePagination && (
                <InlineGroup block center className="executive-decisions-card">
                  <ResultsText
                    size="md"
                    center
                    colour="primaryDark"
                    className="mt-2"
                  >
                    Click on table row to display data.
                  </ResultsText>
                </InlineGroup>
              )}
            </VerticalGroup>
            <VerticalGroup center className="ml-10">
              <Image
                src={Law}
                alt="Law"
                dynamicSize={{ min: "130px", preferred: "15vw", max: "900px" }}
              ></Image>
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      )}
    </RoundResultsContainer>
  );
};

export default RoundResultsRegulatoryCapitalInterventions;
