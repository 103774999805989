import React, { useMemo } from "react";

import Dropdown from "../../atoms/form/input/Dropdown";

interface Props<T> {
  onChange: (selected: { value: T; label: string }) => void;
  options: Array<{ value: T; label: string }>;
  value?: T;
  error?: string;
  label?: string;
  name?: string;
  disabled?: boolean;
  small?: boolean;
  tiny?: boolean;
  midsize?: boolean;
}

function EnumDropdown<T>({
  onChange,
  options,
  value,
  error,
  label,
  name,
  disabled = false,
  small,
  tiny,
  midsize,
}: Props<T>) {
  const selectedValue = useMemo(() => {
    const selectedOption = options.find((option) => option.value === value);

    return selectedOption || options[0];
  }, [options, value]);

  return (
    <Dropdown
      small={small}
      tiny={tiny}
      midsize={midsize}
      data-test={name ?? "enum-dropdown"}
      label={label}
      error={error}
      selectProps={{
        isDisabled: disabled,
        options: options,
        value: selectedValue,
        onChange,
        classNamePrefix: name ?? "enum-dropdown",
      }}
    />
  );
}

export function getValueFunc<T>(options: Array<{ value: T; label: string }>) {
  return (value: T) => {
    const option = options.find((option) => option.value === value);
    return option?.label ?? "";
  };
}

export default EnumDropdown;
