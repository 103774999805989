import React, { useCallback } from "react";
import UserForm from "./UserForm";
import Container from "../../../atoms/page/Page";
import API from "../../../../services/api";
import { useHistory } from "react-router-dom";

function CreateUser() {
  const onSave = useCallback((data: API.UserRequest) => {
    return API.createUser(data);
  }, []);

  const history = useHistory();

  const onCancel = useCallback(() => {
    history.push("/administration/users");
  }, [history]);

  return (
    <Container>
      <UserForm onSave={onSave} onCancel={onCancel} />
    </Container>
  );
}

export default CreateUser;
