import React, { useCallback, useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";
import Banner from "../../../atoms/banner/Banner";
import Icon from "../../../atoms/icon/Icon";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import Text from "../../../atoms/text/Text";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import IconButton from "../../../molecules/iconbutton/IconButton";
import RoundDropdown from "../../../organisms/round-dropdown/RoundDropdown";
import SimulationDropdown from "../../../organisms/simulation-dropdown/SimulationDropdown";
import ReportingTemplatePreviewContent from "../components/ReportingTemplatePreviewContent";
import ReportingTemplatePreviewContentFetcher from "../components/ReportingTemplatePreviewContentFetcher";
import ReportingTemplatePreviewHeader from "../components/ReportingTemplatePreviewHeader";
import ReportingTemplatePreviewModal from "../components/ReportingTemplatePreviewModal";
import {
  formatDuration,
  getTotalDurationEstimate,
} from "../utils/durationUtils";
import { useMemoRequest } from "../../../../hooks/useMemoRequest";
import modelApi from "../../../../services/modelApi";
import api from "../../../../services/api";

interface Props {
  reportingTemplateId: string;
}

export const GenericPreview: React.FC<Props> = ({ reportingTemplateId }) => {
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState<"fullscreen" | "thumbnails">("thumbnails");
  const [simulationId, setSimulationId] = useState<string | null>(null);
  const [maxNumberOfRounds, setMaxNumberOfRounds] = useState<
    number | undefined
  >(undefined);
  const [round, setRound] = useState(1);
  const [headingRef, { height: headingHeight }] = useElementSize();
  const getDefaultSimulation = useCallback(async () => {
    if (!showModal) {
      return { simulationId: null, maxRounds: 0 };
    }
    const result = await modelApi.getDefaultSimulation(reportingTemplateId);
    if (result.simulationId) {
      const sim = await api.getSimulation(result.simulationId);
      return {
        simulationId: result.simulationId,
        maxRounds: sim.generalSetup?.rounds,
      };
    }
  }, [reportingTemplateId, showModal]);

  const { data, refresh } = useMemoRequest(getDefaultSimulation);
  useEffect(() => {
    if (data) {
      setSimulationId(data.simulationId);
      setMaxNumberOfRounds(data.maxRounds);
    }
  }, [data]);

  useEffect(() => {
    if (showModal) {
      refresh();
    }
  }, [showModal, refresh]);

  return (
    <>
      <IconButton
        icon="preview"
        text="Preview"
        onClick={() => {
          setShowModal(true);
        }}
      />
      <ReportingTemplatePreviewModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        headingHeight={headingHeight}
      >
        {({ previewHeight, previewWidth, availableHeight, availableWidth }) => (
          <>
            <ReportingTemplatePreviewHeader
              mode={mode}
              setMode={setMode}
              ref={headingRef}
            >
              <InlineGroup spaceBetweenElements={2} verticalCenter>
                <Text size="sm" bold>
                  Simulation
                </Text>
                <SimulationDropdown
                  simulationId={simulationId ?? undefined}
                  onSimulationSelected={(
                    simulation: API.SimulationResponse,
                  ) => {
                    setSimulationId(simulation.id);
                    setMaxNumberOfRounds(simulation.generalSetup.rounds);
                  }}
                />
              </InlineGroup>
              <InlineGroup spaceBetweenElements={2} verticalCenter>
                <Text>Round:</Text>
                <RoundDropdown
                  value={round}
                  onChange={(selected) => setRound(parseInt(selected.value))}
                  min={1}
                  max={maxNumberOfRounds}
                  tiny
                />
              </InlineGroup>
            </ReportingTemplatePreviewHeader>
            {!simulationId && (
              <VerticalGroup center verticalCenter>
                <Icon type="simulations" size={24} colour="secondaryDark4" />
                <Text colour="secondaryDark3" size="xl">
                  Please select a simulation to preview
                </Text>
              </VerticalGroup>
            )}
            {simulationId && (
              <ReportingTemplatePreviewContentFetcher
                reportingTemplateId={reportingTemplateId}
                simulationId={simulationId}
                round={round}
              >
                {({ inProgress, error, data }) => (
                  <>
                    {error && (
                      <Banner
                        type="error"
                        active={!!error}
                        message={error?.message}
                      />
                    )}
                    {inProgress && <LoadingSpinner />}
                    {data && (
                      <VerticalGroup wide>
                        <Text style={{ paddingLeft: "10px" }} size="lg">
                          Round {round} Total Duration:{" "}
                          {formatDuration(
                            getTotalDurationEstimate(data.layouts, round, 0),
                          )}
                        </Text>
                        <ReportingTemplatePreviewContent
                          round={round}
                          data={data}
                          mode={mode}
                          setMode={setMode}
                          previewHeight={previewHeight - 20}
                          previewWidth={previewWidth - 20}
                          availableHeight={availableHeight - 50}
                          availableWidth={availableWidth + 10}
                        />
                      </VerticalGroup>
                    )}
                  </>
                )}
              </ReportingTemplatePreviewContentFetcher>
            )}
          </>
        )}
      </ReportingTemplatePreviewModal>
    </>
  );
};
