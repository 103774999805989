import React, { useCallback, useEffect, useRef, useState } from "react";
import openNewTab from "../../../lib/openNewTab";
import { wait } from "../../../lib/wait";
import modelApi from "../../../services/modelApi";
import API from "../../../services/api";
import Banner from "../../atoms/banner/Banner";
import Text from "../../atoms/text/Text";
import InlineGroup from "../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../atoms/verticalgroup/VerticalGroup";
import CloseRoundModal from "./CloseRoundModal";
import Icon, { IconType } from "../../atoms/icon/Icon";
import { usePollingModelConfiguration } from "../../../hooks/useModelConfiguration";
import PreviewReportingModal from "./PreviewReportingModal";
import Card from "../../atoms/card/Card";
import ResetGameModal from "./ResetGameModal";
import PresentResultsModal from "./PresentResultsModal";
import SquareIconButton from "../../molecules/square-icon-button/SquareIconButton";
import ProgressModal from "../../organisms/standard-modal/ProgressModal";
import { Noop } from "../../../constants/functions";
import classNames from "classnames";
import Button from "../../atoms/button/Button";
import Checkbox from "../../atoms/form/input/Checkbox";
import Toggle from "../../atoms/toggle/Toggle";
import { ThemeColours } from "../../../types/theme";
import GameControllerFacilitatorAdjustmentsModal from "./GameControllerFacilitatorAdjustmentsModal";
import Grid from "../../atoms/grid/Grid";
import useIsMobileOrTablet from "../../../hooks/useIsMobileOrTablet";

interface UIState {
  closeModalOpen: boolean;
  previewModalOpen: boolean;
  resetModalOpen: boolean;
  presentModalOpen: boolean;
  presentIntroModalOpen: boolean;
}
interface Props {
  closeRound: () => Promise<void>;
  totalRounds: number;
  gameId: string;
  round: number;
  shouldCalculateRound: number;
  setShouldCalculateRound: (round: number) => void;
}

const statuses = [
  "initialising",
  "initialised",
  "initial-results-uploaded",
  "round-calculating",
  "round-calculated",
  "round-sculpting",
  "round-sculpted",
  "reporting-calculating",
  "reporting-calculated",
  "reporting-publishing",
  "reporting-published",
  "results-uploaded",
];

const isPastDesiredStatus = ({
  modelConfig,
  desiredStatus,
  round,
}: {
  modelConfig: ModelAPI.ConfigurationResponse | null;
  desiredStatus: ModelAPI.ModelState;
  round: number;
}) =>
  modelConfig != null &&
  modelConfig.currentRound === round &&
  statuses.indexOf(modelConfig.state) >= statuses.indexOf(desiredStatus);

const Status = ({
  modelConfig,
  desiredStatus,
  label,
  round,
}: {
  modelConfig: ModelAPI.ConfigurationResponse | null;
  desiredStatus: ModelAPI.ModelState;
  label: string;
  round: number;
}) => {
  const completed = isPastDesiredStatus({ modelConfig, desiredStatus, round });
  return (
    <InlineGroup spaceBetweenElements={2}>
      <Icon
        noMargin
        type="status"
        colour={completed ? "lightgreen" : "grey1"}
      />
      <Text>{label}</Text>
    </InlineGroup>
  );
};

const Layout: React.FC = ({ children }) => {
  const isMobileOrTablet = useIsMobileOrTablet();
  if (isMobileOrTablet) {
    return (
      <VerticalGroup spaceBetweenElements={2} wide>
        {children}
      </VerticalGroup>
    );
  }
  return (
    <InlineGroup block spaceBetweenElements={2}>
      {children}
    </InlineGroup>
  );
};

interface ButtonWithIconProps {
  onClick: () => void;
  inProgress?: boolean;
  completed?: boolean;
  bgColour: ThemeColours;
  colour: ThemeColours;
  label: string;
  icon: IconType;
  disabled?: boolean;
}
const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  onClick,
  inProgress,
  completed,
  bgColour,
  colour,
  label,
  icon,
  disabled,
}) => {
  return (
    <VerticalGroup
      bgColour="grey1"
      wide
      className="p-4"
      curved
      center
      spaceBetweenElements={2}
    >
      <SquareIconButton
        className="w-28"
        icon={icon}
        inProgress={inProgress}
        onClick={onClick}
        bgColour={bgColour}
        colour={colour}
        noBorder
        curved
        disabled={disabled}
        completed={completed}
      />
      <Text bold size="lg">
        {label}
      </Text>
    </VerticalGroup>
  );
};

function CloseRoundScreen({
  closeRound,
  gameId,
  totalRounds,
  round,
  shouldCalculateRound,
  setShouldCalculateRound,
}: Props) {
  const [
    {
      closeModalOpen,
      previewModalOpen,
      resetModalOpen,
      presentModalOpen,
      presentIntroModalOpen,
    },
    setUIState,
  ] = useState<UIState>({
    closeModalOpen: false,
    previewModalOpen: false,
    resetModalOpen: false,
    presentModalOpen: false,
    presentIntroModalOpen: false,
  });
  const [inProgress, setInprogress] = useState(false);
  const [closeInProgress, setCloseInprogress] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { data: modelConfig } = usePollingModelConfiguration(gameId, 2000);
  const configRef = useRef<ModelAPI.ConfigurationResponse | null>();
  const [publishResults, setPublishResults] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showAdjustmentsModal, setShowAdjustmentsModal] = useState(false);

  configRef.current = modelConfig;
  const isMobileOrTablet = useIsMobileOrTablet();

  const calculateResults = useCallback(async () => {
    try {
      setError(null);
      setInprogress(true);
      await modelApi.recalculateAll(gameId, round);
      await wait(5000);
      let iteration = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        iteration++;
        if (
          configRef.current?.state === "results-uploaded" &&
          configRef.current?.currentRound === round
        ) {
          break;
        }
        if (iteration > 200) {
          throw new Error("Timed out sculpting");
        }
        await wait(1000);
      }
      setInprogress(false);
    } catch (e) {
      setError(e.message);
      setInprogress(false);
    }
  }, [gameId, round]);

  useEffect(() => {
    if (
      round === 1 &&
      modelConfig?.currentRound === 1 &&
      shouldCalculateRound === 1 &&
      modelConfig?.state === "initial-results-uploaded"
    ) {
      calculateResults();
      setShouldCalculateRound(0);
    } else if (
      round <= totalRounds &&
      shouldCalculateRound === round &&
      modelConfig?.currentRound === round - 1
    ) {
      calculateResults();
      setShouldCalculateRound(0);
    }
  }, [
    calculateResults,
    modelConfig?.currentRound,
    round,
    shouldCalculateRound,
    totalRounds,
    setShouldCalculateRound,
    modelConfig?.state,
  ]);

  const publishResultsAndCloseRound = useCallback(async () => {
    try {
      setError(null);
      setCloseInprogress(true);
      if (publishResults) {
        await modelApi.publishReporting(gameId, round);
      }
      await API.closeRound(gameId, round);
      setCloseInprogress(false);
      closeRound();
    } catch (e) {
      setCloseInprogress(false);
      throw e;
    }
  }, [closeRound, gameId, round, publishResults]);

  const uploadInitialResults = useCallback(async () => {
    try {
      setError(null);
      setInprogress(true);
      await modelApi.uploadResults(gameId, 1);
      let iteration = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        iteration++;
        if (configRef.current?.state === "initial-results-uploaded") {
          break;
        }
        if (iteration > 100) {
          throw new Error("Timed out uploading");
        }
        await wait(2000);
      }
      setInprogress(false);
    } catch (e) {
      setError(e.message);
      setInprogress(false);
    }
  }, [gameId]);

  const onPreviewClose = useCallback(() => {
    setUIState((prevState) => ({ ...prevState, previewModalOpen: false }));
  }, [setUIState]);

  const getPercentageDone = useCallback(() => {
    if (modelConfig?.state === "round-calculating") {
      return 0 / 7;
    }
    if (modelConfig?.state === "round-calculated") {
      return 1 / 7;
    }
    if (modelConfig?.state === "round-sculpting") {
      return 2 / 7;
    }
    if (modelConfig?.state === "round-sculpted") {
      return 3 / 7;
    }
    if (modelConfig?.state === "reporting-calculating") {
      return 4 / 7;
    }
    if (modelConfig?.state === "reporting-calculated") {
      return 5 / 7;
    }
    if (modelConfig?.state === "results-uploaded") {
      return 6 / 7;
    }
    return 0;
  }, [modelConfig?.state]);

  const getClosingPercentageDone = useCallback(() => {
    if (modelConfig?.state === "reporting-calculated") {
      return 0 / 3;
    }
    if (modelConfig?.state === "reporting-publishing") {
      return 1 / 3;
    }
    if (modelConfig?.state === "reporting-published") {
      return 2 / 3;
    }
    return 0;
  }, [modelConfig?.state]);

  const canProceed =
    modelConfig != null &&
    modelConfig.currentRound === round &&
    statuses.indexOf(modelConfig.state) >=
      statuses.indexOf("reporting-calculated");
  const handleOpenAdjustmentsModal = useCallback(() => {
    setShowAdjustmentsModal(true);
  }, [setShowAdjustmentsModal]);
  const handleCloseAdjustmentsModal = useCallback(() => {
    setShowAdjustmentsModal(false);
  }, [setShowAdjustmentsModal]);

  return (
    <>
      {closeModalOpen && (
        <CloseRoundModal
          isOpen={closeModalOpen}
          onClose={() =>
            setUIState((prevState) => ({ ...prevState, closeModalOpen: false }))
          }
          onComplete={publishResultsAndCloseRound}
          totalRounds={totalRounds}
          round={round}
          publishResults={publishResults}
        />
      )}
      {previewModalOpen && (
        <PreviewReportingModal
          eventId={gameId}
          isOpen={previewModalOpen}
          round={round}
          onClose={onPreviewClose}
        />
      )}
      {resetModalOpen && (
        <ResetGameModal
          eventId={gameId}
          isOpen={resetModalOpen}
          onClose={() =>
            setUIState((prevState) => ({ ...prevState, resetModalOpen: false }))
          }
          onComplete={() => {
            setUIState((prevState) => ({
              ...prevState,
              resetModalOpen: false,
            }));
          }}
        />
      )}
      {(presentModalOpen || presentIntroModalOpen) && (
        <PresentResultsModal
          eventId={gameId}
          isOpen={presentModalOpen || presentIntroModalOpen}
          onClose={() =>
            setUIState((prevState) => ({
              ...prevState,
              presentModalOpen: false,
              presentIntroModalOpen: false,
            }))
          }
        />
      )}
      {inProgress && modelConfig?.state !== "initialised" && (
        <ProgressModal
          percentageDone={getPercentageDone() * 100}
          message={
            modelConfig?.currentRound === round && modelConfig?.progressMessage
              ? modelConfig.progressMessage
              : "Calculating"
          }
          onClose={Noop}
        />
      )}
      {closeInProgress && modelConfig?.state !== "initialised" && (
        <ProgressModal
          percentageDone={getClosingPercentageDone() * 100}
          message={
            modelConfig?.currentRound === round && modelConfig?.progressMessage
              ? modelConfig.progressMessage
              : "Closing Round"
          }
          onClose={Noop}
        />
      )}
      {error && <Banner type="error" message={error} active />}
      {
        <VerticalGroup spaceBetweenElements={8} wide>
          <Layout>
            <Card
              className={classNames({
                "width-40-percent": !isMobileOrTablet,
                "width-100-percent": isMobileOrTablet,
              })}
            >
              <VerticalGroup spaceBetweenElements={3}>
                <InlineGroup className="h-8" verticalCenter>
                  <Text bold size="lg">
                    Calculations
                  </Text>
                </InlineGroup>

                <ButtonWithIcon
                  onClick={calculateResults}
                  inProgress={
                    modelConfig?.state !== "initialised" && inProgress
                  }
                  disabled={round > totalRounds}
                  completed={
                    isPastDesiredStatus({
                      modelConfig,
                      desiredStatus: "reporting-calculated",
                      round,
                    })
                      ? true
                      : undefined
                  }
                  bgColour="yellow"
                  colour="white"
                  icon="calculator"
                  label={
                    isPastDesiredStatus({
                      modelConfig,
                      desiredStatus: "reporting-calculated",
                      round,
                    })
                      ? "Recalculate"
                      : "Calculate"
                  }
                />

                <hr />
                <VerticalGroup spaceBetweenElements={2}>
                  <Status
                    label="Results Calculated"
                    modelConfig={modelConfig}
                    round={round}
                    desiredStatus="round-calculated"
                  />
                  <Status
                    label="Treasury Calculated"
                    modelConfig={modelConfig}
                    round={round}
                    desiredStatus="round-sculpted"
                  />
                  <Status
                    label="Results Slides Generated"
                    modelConfig={modelConfig}
                    round={round}
                    desiredStatus="reporting-calculated"
                  />
                </VerticalGroup>
              </VerticalGroup>
            </Card>
            <Card flex={!isMobileOrTablet} wide={isMobileOrTablet}>
              <VerticalGroup spaceBetweenElements={3}>
                <InlineGroup block spread verticalCenter className="h-8">
                  <Text bold size="lg">
                    Results Slides
                  </Text>
                  {/* <PresentResultsRndDropdown
                    onChange={handleRoundToPresentChange}
                    maxRound={round}
                    currentRound={roundToPresent ?? round}
                  /> */}
                </InlineGroup>
                <InlineGroup block evenWidthChildren spaceBetweenElements={2}>
                  <ButtonWithIcon
                    onClick={() =>
                      setUIState((state) => ({
                        ...state,
                        previewModalOpen: true,
                      }))
                    }
                    // disabled={
                    //   roundToPresent == null &&
                    //   !isPastDesiredStatus({
                    //     modelConfig,
                    //     desiredStatus: "reporting-calculated",
                    //     round,
                    //   })
                    // }
                    bgColour="yellow"
                    colour="white"
                    icon="preview"
                    label="Preview"
                  />
                  <ButtonWithIcon
                    onClick={() =>
                      setUIState((state) => ({
                        ...state,
                        presentModalOpen: true,
                      }))
                    }
                    // disabled={
                    //   roundToPresent == null &&
                    //   !isPastDesiredStatus({
                    //     modelConfig,
                    //     desiredStatus: "reporting-calculated",
                    //     round,
                    //   })
                    // }
                    bgColour="yellow"
                    colour="white"
                    icon="presentation"
                    label="Present"
                  />
                </InlineGroup>
                <hr />
              </VerticalGroup>
            </Card>
          </Layout>
          <InlineGroup block spread>
            {round <= totalRounds ? (
              <VerticalGroup spaceBetweenElements={2}>
                <Button
                  disabled={!canProceed}
                  onClick={() =>
                    setUIState((state) => ({ ...state, closeModalOpen: true }))
                  }
                >
                  Next Round
                </Button>
                <Checkbox
                  label="Publish results when proceeding"
                  name="publishResults"
                  onChange={(e) => {
                    setPublishResults(e.target.checked);
                  }}
                  checked={publishResults}
                />
              </VerticalGroup>
            ) : (
              <InlineGroup verticalCenter spaceBetweenElements={2}>
                <Icon type="tick" size={8} />
                <Text bold size="xl">
                  Game Complete
                </Text>
              </InlineGroup>
            )}
            <VerticalGroup spaceBetweenElements={2}>
              <Toggle
                onUpdate={(checked) => setShowAdvanced(checked)}
                checked={showAdvanced}
              />
              <Text>Advanced</Text>
            </VerticalGroup>
          </InlineGroup>

          {showAdvanced && (
            <Card wide>
              <VerticalGroup wide spaceBetweenElements={8}>
                <Text bold size="lg">
                  Advanced
                </Text>
                <Grid wide columns={isMobileOrTablet ? 2 : 3}>
                  <ButtonWithIcon
                    onClick={handleOpenAdjustmentsModal}
                    bgColour="yellow"
                    colour="white"
                    label="Adjustments"
                    icon="slider"
                  />
                  {round === 1 && (
                    <ButtonWithIcon
                      icon="presentation"
                      label="Present Intro"
                      bgColour="yellow"
                      colour="white"
                      onClick={() => {
                        setUIState((state) => ({
                          ...state,
                          presentIntroModalOpen: true,
                        }));
                      }}
                    />
                  )}
                  {round === 1 && (
                    <ButtonWithIcon
                      icon="upload"
                      label="Upload Rnd0 Data"
                      bgColour="yellow"
                      colour="white"
                      onClick={uploadInitialResults}
                      disabled={
                        !isPastDesiredStatus({
                          modelConfig,
                          desiredStatus: "initialised",
                          round,
                        })
                      }
                      completed={isPastDesiredStatus({
                        modelConfig,
                        desiredStatus: "initial-results-uploaded",
                        round,
                      })}
                    />
                  )}
                  <ButtonWithIcon
                    onClick={() =>
                      openNewTab(
                        `${window.location.origin}/events/${gameId}/debug`,
                      )
                    }
                    bgColour="blue"
                    colour="white"
                    icon="preview"
                    label="View Model"
                  />
                  <ButtonWithIcon
                    onClick={() =>
                      setUIState((state) => ({
                        ...state,
                        resetModalOpen: true,
                      }))
                    }
                    bgColour="danger"
                    colour="white"
                    icon="trash"
                    label="Reset"
                  />
                </Grid>
                {round === 1 && (
                  <>
                    <hr />
                    <VerticalGroup spaceBetweenElements={2}>
                      <Status
                        label="Model Initialised"
                        modelConfig={modelConfig}
                        round={round}
                        desiredStatus="initialised"
                      />
                      <Status
                        label="Initial Results Uploaded (AWS Model)"
                        modelConfig={modelConfig}
                        round={round}
                        desiredStatus="initial-results-uploaded"
                      />
                    </VerticalGroup>
                  </>
                )}
              </VerticalGroup>
            </Card>
          )}
        </VerticalGroup>
      }

      <GameControllerFacilitatorAdjustmentsModal
        isOpen={showAdjustmentsModal}
        onClose={handleCloseAdjustmentsModal}
        onRecalculate={calculateResults}
      />
    </>
  );
}

export default CloseRoundScreen;
