import React, { useMemo } from "react";
import RoundResultsPage from "../../results/RoundResultsPage";
import ContentStretcher from "./ContentStretcher";
import Clickable from "../../../atoms/clickable/Clickable";
import HoverableIcon from "../../../atoms/icon/HoverableIcon";
import Icon from "../../../atoms/icon/Icon";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import useIsMobile from "../../../../hooks/useIsMobile";
import classNames from "classnames";
import "./ReportingPagePreviewTile.scss";

export const THUMBNAIL_COLUMN_WIDTH = 400;
export const THUMBNAIL_WIDTH = THUMBNAIL_COLUMN_WIDTH - 20;
export const RENDERED_DIMENSIONS = { width: 1945, height: 1000 };

export interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  layout: API.ReportingTemplateLayoutResponse;
  onLayoutUpdate?: (
    layoutId: string,
    data: API.ReportingTemplateLayoutUpdateRequest,
  ) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  data: ModelAPI.Reporting.ReportingResults;
  showHeader?: boolean;
  showTeamPositions?: boolean;
  overlay?: React.ReactNode;
  roundNumber?: number;
  useOverrideDisabled?: boolean;
  dndDragProps?: any;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
}

const ReportingPagePreviewTile: React.FC<Props> = ({
  disabled,
  layout,
  onLayoutUpdate,
  dragHandleProps,
  data,
  showHeader,
  showTeamPositions,
  overlay,
  roundNumber,
  style,
  useOverrideDisabled,
  dndDragProps,
  thumbnailWidth = 380,
  thumbnailHeight = 200,
}) => {
  const isMobile = useIsMobile();
  const containerClasses = useMemo(() => {
    return classNames("ReportingPagePreviewTile", {
      "container-mobile": isMobile,
    });
  }, [isMobile]);

  const contentClasses = useMemo(() => {
    return classNames({
      "content-mobile": isMobile,
    });
  }, [isMobile]);
  const renderContent = () => (
    <>
      <div
        style={{
          width: isMobile ? "100%" : RENDERED_DIMENSIONS.width,
          height: isMobile ? "100%" : RENDERED_DIMENSIONS.height,
          minHeight: isMobile ? "" : RENDERED_DIMENSIONS.height,
          position: "absolute",
        }}
      >
        <RoundResultsPage
          data={data}
          roundNumber={roundNumber ?? 3}
          onlyThesePages={[layout.type]}
          hidePagination
          hideHeader={!showHeader}
          hideTeamPositions={!showTeamPositions}
          noAnimations
          noAudio
          ignoreKeyPress
          allowHiddenLayouts
        />
      </div>
      {(!!onLayoutUpdate ||
        !!dragHandleProps ||
        !!overlay ||
        !!dndDragProps) && (
        <div
          style={{
            width: isMobile ? "100%" : RENDERED_DIMENSIONS.width,
            height: isMobile ? "100%" : RENDERED_DIMENSIONS.height,
            minHeight: isMobile ? "" : RENDERED_DIMENSIONS.height,
            position: "absolute",
            backgroundColor:
              !!onLayoutUpdate || !!dragHandleProps
                ? layout.enabled
                  ? "rgba(0, 0, 0, 0.3)"
                  : "rgba(0, 0, 0, 0.6)"
                : undefined,
          }}
        >
          {!!onLayoutUpdate && (
            <Clickable
              disabled={disabled}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (onLayoutUpdate) {
                  if (useOverrideDisabled) {
                    onLayoutUpdate(layout.id, {
                      overrideDisabled: layout.overrideDisabled ? false : true,
                    });
                  } else {
                    onLayoutUpdate(layout.id, { enabled: !layout.enabled });
                  }
                }
              }}
            >
              <HoverableIcon
                size={32}
                fill={
                  layout.enabled && !layout.overrideDisabled ? "white" : "red"
                }
                hoverType={
                  layout.enabled && !layout.overrideDisabled ? "hidden" : "eye"
                }
                type={
                  layout.enabled && !layout.overrideDisabled ? "eye" : "hidden"
                }
              />
            </Clickable>
          )}
          {!isMobile && !!dndDragProps && (
            <Clickable
              disabled={disabled}
              style={{
                position: "absolute",
                top: "0%",
                right: "0%",
                padding: 30,
              }}
              id={dndDragProps.id}
              ref={dndDragProps.ref}
              {...dndDragProps.attributes}
              {...dndDragProps.listeners}
            >
              <Icon size={16} fill="white" type="draggable" />
            </Clickable>
          )}
          {!isMobile && !!dragHandleProps && (
            <Clickable
              disabled={disabled}
              style={{
                position: "absolute",
                top: "0%",
                right: "0%",
                padding: 30,
              }}
              {...dragHandleProps}
            >
              <Icon size={16} fill="white" type="draggable" />
            </Clickable>
          )}
          {!!overlay && overlay}
        </div>
      )}
    </>
  );

  if (isMobile) {
    return (
      <div className={containerClasses}>
        <div className={contentClasses}>{renderContent()}</div>
      </div>
    );
  }

  return (
    <ContentStretcher
      className="ReportingPagePreviewTile"
      style={{
        background: "#e1e1e1",
        width: thumbnailWidth,
        height: thumbnailHeight,
        minHeight: thumbnailHeight,

        ...style,
      }}
      contentWidth={RENDERED_DIMENSIONS.width}
      contentHeight={RENDERED_DIMENSIONS.height}
    >
      {renderContent()}
    </ContentStretcher>
  );
};

export default React.memo(ReportingPagePreviewTile);
