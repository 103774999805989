import React, { useCallback } from "react";
import ChallengeModal from "../../organisms/challenge-modal/ChallengeModal";

import useAPIRequest from "../../../hooks/useAPIRequest";
import API from "../../../services/api";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  reportingTemplateId: string;
  layout: API.ReportingTemplateLayoutResponse;
}

function DeleteReportingTemplateLayout({
  isOpen,
  onComplete,
  onClose,
  reportingTemplateId,
  layout,
}: Props) {
  const callback = useCallback(
    () => API.deleteReportingTemplateLayout(reportingTemplateId, layout.id),
    [reportingTemplateId, layout],
  );

  const [{ inProgress, error }, doAPIRequest] = useAPIRequest(callback, null);

  const handleConfirm = () => {
    doAPIRequest(onComplete);
  };

  return (
    <ChallengeModal
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmInProgress={inProgress}
      challengeQuestion="This action is irreversible. Please type the word DELETE below to delete this slide."
      challengeAnswer="DELETE"
      challengeAnswerWarning="Please type the word DELETE in CAPS to delete the slide."
      title="Delete slide?"
      confirmTitle="Delete"
      error={error && error.message}
      warning
    />
  );
}

export default DeleteReportingTemplateLayout;
