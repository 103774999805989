import React, { useCallback } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Icon from "../../../atoms/icon/Icon";
import Image from "../../../atoms/image/Image";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Courtroom from "../../../../assets/results/Courtroom.png";
import { thousandSeparator } from "../../model/utils/formatters";
import { TeamsWithColours } from "../RoundResultsPage";
import "../RoundResultsTablePage.scss";
import { useResultsContext } from "../context/context";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";

interface Props {
  teams: TeamsWithColours[];
  amlEvent: ModelAPI.Reporting.AmlEvent;
  currencyDenomination: string;
}

export const maxClicks = 4;

const RoundResultsNeoBankAmlIssue: React.FC<Props> = ({
  teams,
  amlEvent,
  currencyDenomination,
}) => {
  const context = useResultsContext();
  const team = teams.find((t) => t.teamId === amlEvent.teamId);

  const onNext = useCallback(() => {
    if (context.clicks === maxClicks) {
      context.goNextPage();
    } else {
      context.addClick();
    }
  }, [context]);

  return (
    <RoundResultsContainer onNext={onNext}>
      <VerticalGroup full center wide spaceBetweenElements={2}>
        <EditableReportingHeading defaultHeading="Breaking News!" />
        <VerticalGroup full center wide spaceBetweenElements={4}>
          <ResultsText size="xl" bold center colour="blue">
            Neobank found to have facilitated financing of terrorism.
          </ResultsText>
          <InlineGroup fullHeight verticalCenter>
            <VerticalGroup
              full
              verticalCenter
              center
              style={{ width: "100%", height: "100%" }}
            >
              <Image
                src={Courtroom}
                alt="Court Room"
                dynamicSize={{ min: "300px", preferred: "40vw", max: "1300px" }}
              ></Image>
            </VerticalGroup>
            <VerticalGroup full spread style={{ width: "120%", height: "90%" }}>
              <ResultsText size="xl" bold className="mb-2">
                Key Findings
              </ResultsText>
              <VerticalGroup full className="pt-8 pb-8" spread>
                <InlineGroup
                  verticalCenter
                  className={
                    context.noAnimations || context.clicks >= 1
                      ? "show-bids pb-4"
                      : "no-show-options"
                  }
                >
                  <Icon
                    type="hammer"
                    colour="primaryDark"
                    dynamicSize={{
                      min: "50px",
                      preferred: "5vw",
                      max: "300px",
                    }}
                  ></Icon>
                  <VerticalGroup wide className="ml-2">
                    <ResultsText bold size="md" className="pb-2">
                      Verdict
                    </ResultsText>
                    <ResultsText size="md">
                      Due to relaxed internal controls, the Neobank recently
                      acquired by{" "}
                      <span
                        style={{ fontWeight: "bold", color: `${team?.colour}` }}
                      >
                        {team?.teamName}
                      </span>{" "}
                      was found to have unknowingly allowed a terrorist
                      organization to open accounts and transfer millions of
                      dollars to finance terrorist operations.
                    </ResultsText>
                  </VerticalGroup>
                </InlineGroup>
                <InlineGroup
                  verticalCenter
                  className={
                    context.noAnimations || context.clicks >= 2
                      ? "show-bids pb-4"
                      : "no-show-options"
                  }
                >
                  <Icon
                    type="dollars"
                    colour="yellow"
                    dynamicSize={{
                      min: "50px",
                      preferred: "5vw",
                      max: "300px",
                    }}
                  ></Icon>
                  <VerticalGroup wide className="ml-2">
                    <ResultsText bold size="md" className="pb-2">
                      Financial Impacts
                    </ResultsText>
                    <ResultsText size="md">
                      The regulator has fined{" "}
                      <span
                        style={{ fontWeight: "bold", color: `${team?.colour}` }}
                      >
                        {team?.teamName}
                      </span>{" "}
                      a significant{" "}
                      <span style={{ fontWeight: "bold" }}>
                        fine of {thousandSeparator(amlEvent.fine)} (
                        {currencyDenomination}).
                      </span>{" "}
                      It is also unlikely the acquired Neobank will be as
                      successful now that it has been publicly exposed for these
                      internal operational risk failures.
                    </ResultsText>
                  </VerticalGroup>
                </InlineGroup>
                <InlineGroup
                  verticalCenter
                  className={
                    context.noAnimations || context.clicks >= 3
                      ? "show-bids pb-4"
                      : "no-show-options"
                  }
                >
                  <Icon
                    type="citizens"
                    colour="blue"
                    dynamicSize={{
                      min: "50px",
                      preferred: "5vw",
                      max: "300px",
                    }}
                  ></Icon>
                  <VerticalGroup wide className="ml-2">
                    <ResultsText bold size="md" className="pb-2">
                      Reputation Impact
                    </ResultsText>
                    <ResultsText size="md">
                      Customers and shareholders are concerned that these events
                      have somehow permeated into the wider banking Group. The
                      media are blaming relaxed due diligence practices and are
                      wondering about the quality of other acquisitions the bank
                      has made.
                    </ResultsText>
                  </VerticalGroup>
                </InlineGroup>
              </VerticalGroup>
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsNeoBankAmlIssue;
