import React from "react";
import classNames from "classnames";
import "./ResultsText.scss";
import { ThemeColours, ThemeSpacing } from "../../../../types/theme";
import DOMPurify from "dompurify";

export type FontSizeMultiplier = 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5;
export interface ResultsTextProps {
  children?: React.ReactNode;
  className?: string;
  colour?: ThemeColours;
  size?: string;
  bold?: boolean;
  italic?: boolean;
  style?: React.CSSProperties;
  center?: boolean;
  isHtml?: boolean;
  lineHeight?: ThemeSpacing;
  mono?: boolean;
  medium?: boolean;
  vertical?: boolean;
  right?: boolean;
  left?: boolean;
  allowOverflow?: boolean;
  color?: string;
  sizeMultiplier?: FontSizeMultiplier;
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

const parseMultiplier = (multiplier: FontSizeMultiplier) => {
  const str = multiplier.toString();
  return str.replace(".", "point");
};

const ResultsText: React.FC<ResultsTextProps> = ({
  children,
  className,
  colour,
  size = "md",
  bold,
  italic,

  center,
  isHtml = false,
  lineHeight,
  mono,
  medium,
  vertical,
  right,
  left,
  allowOverflow,
  color,
  sizeMultiplier,
  ...rest
}) => {
  const colourClass = colour ? `colour-${colour}` : null;
  const lineHeightClass = lineHeight ? `lineheight-${lineHeight}` : null;
  const sizeClass = size ? `size-${size}` : null;
  const sizeMultiplierClass = sizeMultiplier
    ? `size-multiplier-${parseMultiplier(sizeMultiplier)}`
    : null;

  if (isHtml) {
    const sanitizedHtml = DOMPurify.sanitize(String(children));
    return (
      <div
        style={{ color }}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        className={classNames(
          "results-text",
          sizeMultiplierClass,
          lineHeightClass,
          {
            center,
            bold,
            mono,
            medium,
            vertical,
            italic,
            right,
            left,
            allowOverflow,
          },
          colourClass,
          sizeClass,
          className,
        )}
        {...rest}
      />
    );
  }
  return (
    <div
      style={{ color }}
      className={classNames(
        "results-text",
        {
          bold,
          italic,
          center,
          vertical,
          right,
          left,
          lineHeight,
        },
        colourClass,
        sizeClass,
        sizeMultiplierClass,
        lineHeightClass,
        className,
      )}
    >
      {children}
    </div>
  );
};

export default ResultsText;
