import React from "react";
import VerticalGroup from "../../../../atoms/verticalgroup/VerticalGroup";
import ClickableText from "../../../../atoms/text/ClickableText";
import openNewTab from "../../../../../lib/openNewTab";
import ConfirmModal from "../../../../organisms/confirm-modal/ConfirmModal";

interface Props {
  reportingTemplatesThatNeedManualModification: API.ReportingTemplatesThatNeedManualModification[];
  onClose: () => void;
}

const ReportingTemplatesThatNeedManualModificationModal: React.FC<Props> = ({
  reportingTemplatesThatNeedManualModification,
  onClose,
}) => {
  return (
    <ConfirmModal
      isOpen
      onClose={onClose}
      onConfirm={onClose}
      onCancel={onClose}
      confirmTitle="OK"
      title={"Simulations That Need Manual Modification"}
      description={
        <VerticalGroup spaceBetweenElements={4}>
          The following reporting template could not be updated as the text has
          diverged from the base config.
          <br />
          Please manually update the text in the following reporting template
          <VerticalGroup className="mt-4">
            {reportingTemplatesThatNeedManualModification.map(
              (template, index) => (
                <ClickableText
                  key={index}
                  onClick={() => {
                    openNewTab(`/reporting-templates/${template.id}/view`);
                  }}
                >
                  {template.name}
                </ClickableText>
              ),
            )}
          </VerticalGroup>
        </VerticalGroup>
      }
    />
  );
};

export default ReportingTemplatesThatNeedManualModificationModal;
