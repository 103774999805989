import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Icon from "../../atoms/icon/Icon";
import Table from "../../atoms/table/Table";

import StickyBar from "../../organisms/sticky-bar/StickyBar";
import EmptyList from "../../organisms/empty-list/EmptyList";
import IconButton from "../../molecules/iconbutton/IconButton";
import Clickable from "../../atoms/clickable/Clickable";
import ClientAvatarList from "../../molecules/client-avatar-list/ClientAvatarList";
import DeleteReportingTemplate from "./ReportingTemplateDelete";
import {
  CLONE_TEMPLATE_TOOLTIP_ID,
  CloneReportingTemplate,
} from "./actions/CloneReportingTemplate";
import Tooltip from "../../atoms/tooltip/Tooltip";
import RoleRequired from "../../molecules/role-required/RoleRequired";

interface Props {
  templates: API.ReportingTemplateResponse[];
  refreshTemplates: () => void;
}

interface TemplateState {
  deleteModalOpen: boolean;
  templateSelected: API.ReportingTemplateResponse | null;
}

const STICKY_BAR_HEIGHT = 8;
const TOOLTIP_ID = "reporting-templates-table-tooltip";

function ReportingListTable({ templates, refreshTemplates }: Props) {
  const history = useHistory();
  const [{ deleteModalOpen, templateSelected }, setState] =
    useState<TemplateState>({ deleteModalOpen: false, templateSelected: null });

  const onDeleteComplete = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      deleteModalOpen: false,
      templateSelected: null,
    }));
    refreshTemplates();
  }, [refreshTemplates]);

  const handleReportingTemplateClick = useCallback(
    (template: API.ReportingTemplateResponse) => (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      history.push(`/reporting-templates/${template.id}/view`);
    },
    [history],
  );

  const handleDeleteClick = useCallback(
    (template: API.ReportingTemplateResponse) => (event: any) => {
      event.stopPropagation();
      setState({
        deleteModalOpen: true,
        templateSelected: template,
      });
    },
    [],
  );

  return (
    <>
      <Tooltip id={TOOLTIP_ID} />
      <Tooltip id={CLONE_TEMPLATE_TOOLTIP_ID}>
        <>
          <b>Clone Presentation</b>
          <p>
            Create a new template using the details <br /> of this template as a
            starting point.
          </p>
        </>
      </Tooltip>
      <StickyBar height={STICKY_BAR_HEIGHT} spaceBetweenElements={2}>
        <RoleRequired roles={["superadmin", "standard", "admin"]}>
          <IconButton
            data-test="add-template"
            linkTo="/reporting-templates/add"
            icon="report"
            text="Add Template"
          />{" "}
        </RoleRequired>
      </StickyBar>
      {deleteModalOpen && !!templateSelected && (
        <DeleteReportingTemplate
          isOpen={deleteModalOpen}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
              templateSelected: null,
            }))
          }
          onComplete={onDeleteComplete}
          template={templateSelected}
        />
      )}
      {!templates.length && (
        <EmptyList icon="report" message="There are no reporting templates" />
      )}
      {!!templates.length && (
        <Table stickyHeader stickyTop={STICKY_BAR_HEIGHT} clickable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Clients</th>
              <th># Events Using</th>
              <th className="right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {templates.map((template) => (
              <tr
                key={template.id}
                data-test={template.id}
                onClick={handleReportingTemplateClick(template)}
              >
                <td>{template.name}</td>
                <td>
                  {template.availableToAllClients ? (
                    "Available to all clients"
                  ) : (
                    <ClientAvatarList clients={template.clients} />
                  )}
                </td>
                <td>{template.eventsCount}</td>
                <td className="right">
                  <Icon
                    type="search"
                    tt={{ content: "View Template", id: TOOLTIP_ID }}
                  />
                  <RoleRequired roles={["superadmin", "standard", "admin"]}>
                    <CloneReportingTemplate type="icon" template={template} />
                    <Clickable
                      data-test={`delete-${template.id}`}
                      onClick={handleDeleteClick(template)}
                    >
                      <Icon
                        type="trash"
                        tt={{ content: "Delete Template", id: TOOLTIP_ID }}
                      />
                    </Clickable>
                  </RoleRequired>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default ReportingListTable;
