import React from "react";
import RoundResultsSyncer from "./RoundResultsSyncer";
import RoundResultsLayout from "./RoundResultsLayout";
export interface TeamsWithColours extends ModelAPI.Reporting.ReportingTeams {
  colour: string;
  dot?: any;
}

interface Props {
  eventId?: string;
  roundNumber: number;
  data: ModelAPI.Reporting.ReportingResults;
  onlyThesePages: API.ReportingLayoutType[];
  startingLayout?: API.ReportingLayoutType;
  hidePagination?: boolean;
  hideHeader?: boolean;
  showRemoteCloseButton?: boolean;
  hideTeamPositions?: boolean;
  noAnimations?: boolean;
  noAudio?: boolean;
  noVideo?: boolean;
  allowCustomTemplateEdits?: boolean;
  onCustomTemplateEdit?: (
    val: API.ReportingTemplateLayoutUpdateRequest,
  ) => void;
  allowPresentationMode?: boolean;
  syncState?: boolean;
  readSyncState?: boolean;
  ignoreKeyPress?: boolean;
  ignoreFontMultiplier?: boolean;
  onLayoutUpdate?: (
    layoutId: string,
    data: API.ReportingTemplateLayoutUpdateRequest,
  ) => void;
  id?: string;
  allowHiddenLayouts?: boolean;
  refreshPresentation?: (round: number) => void;
  handleRoundChange?: (round: number, calcVersion?: number) => void;
}

const RoundResultsPage: React.FC<Props> = (props: Props) => {
  const { data } = props;

  return (
    <RoundResultsSyncer {...props}>
      <RoundResultsLayout data={data} />
    </RoundResultsSyncer>
  );
};

export default RoundResultsPage;
