import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMemoRequest } from "../../../hooks/useMemoRequest";
import modelApi from "../../../services/modelApi";
import Banner from "../../atoms/banner/Banner";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import RoundResultsFontMultiplierModal from "./components/RoundResultsFontMultiplierModal";
import RoundResultsSyncer from "./RoundResultsSyncer";
import RoundResultsPresenterLayout from "./RoundResultsPresenterLayout";
import RoundResultsNotGeneratedError from "./components/RoundResultsNotGeneratedError";
import { usePollingModelConfiguration } from "../../../hooks/useModelConfiguration";
import RoundResultsOpenPresentationModal from "./RoundResultsOpenPresentationModal";

const RoundResultsPresenter: React.FC = () => {
  const { eventId } = useParams<{
    eventId: string;
  }>();
  const [showOpenWindowModal, setShowOpenWindowModal] = useState(true);

  const getRoundNumberAndCalcVersion = useCallback(async () => {
    try {
      const preso = await modelApi.getReportingPresentationState(eventId, {
        bypass: false,
      });
      return { round: preso.round, calcVersion: preso.calcVersion };
    } catch (error) {
      return { round: 0, calcVersion: 0 };
    }
  }, [eventId]);

  const { inProgress, data, error } = useMemoRequest<{
    round: number;
    calcVersion: number;
  }>(getRoundNumberAndCalcVersion);

  return (
    <>
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {inProgress && <LoadingSpinner />}
      {data != null && (
        <RoundResultsPresenterWithRound
          eventId={eventId}
          roundNumber={data.round}
          calcVersion={data.calcVersion}
        />
      )}
      {showOpenWindowModal && (
        <RoundResultsOpenPresentationModal
          isOpen={showOpenWindowModal}
          eventId={eventId}
          onClose={() => setShowOpenWindowModal(false)}
        />
      )}
    </>
  );
};

const RoundResultsPresenterWithRound: React.FC<{
  eventId: string;
  roundNumber: number;
  calcVersion: number;
}> = ({ eventId, roundNumber, calcVersion }) => {
  const [round, setRound] = useState(roundNumber);
  const [calcVers, setCalcVers] = useState(calcVersion);
  const [textSizeModalOpen, setTextSizeModalOpen] = useState(false);
  const getReportingResults = useCallback(() => {
    return modelApi.getReportingResults(eventId, round);
  }, [eventId, round]);

  const { inProgress, data, error, refresh } =
    useMemoRequest<ModelAPI.Reporting.ReportingResults>(getReportingResults);

  const refreshData = useCallback(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (calcVers !== calcVersion) {
      refreshData();
    }
  }, [calcVers, calcVersion, refreshData]);

  const { data: config } = usePollingModelConfiguration(eventId, 10000);

  const notFoundError = error?.message?.includes("not found");

  const handleRoundChange = useCallback(
    (round: number, calcVersion?: number) => {
      setRound(round);
      if (calcVersion != null) {
        setCalcVers(calcVersion);
      }
    },
    [],
  );

  return (
    <>
      {!notFoundError && error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {notFoundError && (
        <RoundResultsNotGeneratedError
          roundNumber={round}
          handleRoundUpdate={setRound}
        />
      )}
      {inProgress && <LoadingSpinner />}
      {textSizeModalOpen && (
        <RoundResultsFontMultiplierModal
          isOpen={textSizeModalOpen}
          onClose={() => setTextSizeModalOpen(false)}
        />
      )}
      {data && config && (
        <RoundResultsSyncer
          eventId={eventId}
          roundNumber={round}
          data={data}
          onlyThesePages={[]}
          hidePagination
          allowPresentationMode
          syncState
          readSyncState
          ignoreKeyPress
          noAudio
          handleRoundChange={handleRoundChange}
        >
          <RoundResultsPresenterLayout
            data={data}
            onSpeakerNotesSaved={refreshData}
            handleRoundChange={handleRoundChange}
            modelConfig={config}
          />
        </RoundResultsSyncer>
      )}
    </>
  );
};

export default RoundResultsPresenter;
