import React, { useCallback, useState } from "react";
import Table, { Td, Th, Tr } from "../../../../atoms/table/Table";
import Clickable from "../../../../atoms/clickable/Clickable";
import Icon from "../../../../atoms/icon/Icon";
import EditTabletModal from "../components/EditTabletModal";
import DeleteTabletModal from "../components/DeleteTabletModal";
import IconButton from "../../../../molecules/iconbutton/IconButton";
import { useTabletConfigContext } from "../context/context";
import ColourSquare from "../components/colourSquare/ColourSquare";
import InlineGroup from "../../../../atoms/inlinegroup/InlineGroup";
import AddTablet from "../components/AddTablet";
import { teamTabletColourOptions } from "../../../../organisms/team-tablet-colour-dropdown/TeamTabletColourDropdown";

interface TabletProps {
  tabletData: API.TabletMappingResponse;
  refresh: () => void;
}

function TabletConfigTable({ tabletData, refresh }: TabletProps) {
  const [editTabletModalOpen, setEditTabletModalOpen] = useState(false);
  const [deleteTabletModalOpen, setDeleteTabletModalOpen] = useState(false);
  const [selectedTablet, setSelectedTablet] =
    React.useState<API.TabletDetailResponse | null>(null);
  const { eventData } = useTabletConfigContext();
  const [addTabletModalOpen, setAddTabletModalOpen] = useState(false);

  const sortedTablets = React.useMemo(() => {
    return [...tabletData.tablets].sort((a, b) => {
      const colorIndexA = teamTabletColourOptions.indexOf(a.colour);
      const colorIndexB = teamTabletColourOptions.indexOf(b.colour);

      if (colorIndexA !== colorIndexB) {
        return colorIndexA - colorIndexB;
      }

      return a.tabletId - b.tabletId;
    });
  }, [tabletData.tablets]);

  const handleEditTabletModalClick = useCallback(
    (tablet: API.TabletDetailResponse) => {
      setSelectedTablet(tablet);
      setEditTabletModalOpen(true);
    },
    [],
  );

  const handleDeleteTabletModalClick = useCallback(
    (tablet: API.TabletDetailResponse) => {
      setSelectedTablet(tablet);
      setDeleteTabletModalOpen(true);
    },
    [],
  );

  const onClose = useCallback(() => {
    setEditTabletModalOpen(false);
    setDeleteTabletModalOpen(false);
  }, []);

  const handleAddTabletModalClick = useCallback(() => {
    setAddTabletModalOpen(true);
  }, []);

  const handleAddTabletModalClose = useCallback(() => {
    setAddTabletModalOpen(false);
  }, []);

  const handleAddTabletConfirm = useCallback(() => {
    setAddTabletModalOpen(false);
    refresh();
  }, [refresh]);

  const getEventName = useCallback(
    (eventId?: string | null) => {
      if (!eventId) {
        return "";
      }
      const data = eventData[eventId];
      if (!data) {
        return eventId;
      }
      return `${data.client} - ${data.name}`;
    },
    [eventData],
  );

  if (!tabletData.tablets || tabletData.tablets.length === 0) {
    return <div>No tablets available.</div>;
  }

  return (
    <>
      <EditTabletModal
        isOpen={editTabletModalOpen}
        onClose={onClose}
        tablet={selectedTablet}
        refresh={refresh}
        tabletData={tabletData}
      />
      {selectedTablet && (
        <DeleteTabletModal
          isOpen={deleteTabletModalOpen}
          onClose={onClose}
          tablet={selectedTablet}
          onConfirm={refresh}
        />
      )}
      {addTabletModalOpen && (
        <AddTablet
          isOpen
          onClose={handleAddTabletModalClose}
          tabletData={tabletData}
          onConfirm={handleAddTabletConfirm}
        />
      )}
      <InlineGroup spaceBetweenElements={4}>
        <IconButton
          icon="assignTablet"
          text="Add Tablet"
          onClick={handleAddTabletModalClick}
        />
      </InlineGroup>
      <Table stickyHeader>
        <thead>
          <Tr>
            <Th>Tablet ID</Th>
            <Th>Event</Th>
            <Th>Team ID</Th>
            <Th>Email</Th>
            <Th>Colour</Th>
            <Th>Available</Th>
            <Th right>Actions</Th>
          </Tr>
        </thead>
        <tbody>
          {sortedTablets.map((tablet, index) => {
            return (
              <Tr key={index}>
                <Td>{tablet.tabletId}</Td>
                <Td>{getEventName(tablet.eventId)}</Td>
                <Td>{tablet.teamId || ""}</Td>
                <Td>{tablet.email || ""}</Td>
                <Td>
                  <ColourSquare colour={tablet.colour} />
                </Td>
                <Td>{tablet.available ? "Yes" : "No"}</Td>
                <Td className="right">
                  <InlineGroup verticalCenter right>
                    <Clickable
                      onClick={(event) => {
                        event.stopPropagation();
                        handleEditTabletModalClick(tablet);
                      }}
                    >
                      <Icon
                        size={6}
                        type="editTablet"
                        tip={{
                          content: "Edit Tablet",
                          id: `${tablet.tabletId}-edit`,
                        }}
                      />
                    </Clickable>
                    <Clickable
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteTabletModalClick(tablet);
                      }}
                    >
                      <Icon
                        type="trash"
                        tip={{
                          content: "Delete Tablet",
                          id: `${tablet.tabletId}-delete`,
                        }}
                      />
                    </Clickable>
                  </InlineGroup>
                </Td>
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

export default TabletConfigTable;
