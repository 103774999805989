import React, { useCallback, useState } from "react";
import API from "../../../../services/api";
import IconButton from "../../../molecules/iconbutton/IconButton";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import ReportingPagePreviewTile from "../components/ReportingPagePreviewTile";
import Clickable from "../../../atoms/clickable/Clickable";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../atoms/text/Text";
import Container from "../../../atoms/page/Page";

interface Props {
  template: API.ReportingTemplateResponse;
  previewData: ModelAPI.Reporting.ReportingResults;
  sort: number;
  onFinish: (newLayout: API.ReportingTemplateLayoutResponse) => void;
}

const FAKE_TEXT_LAYOUT: API.ReportingTemplateLayoutResponse = {
  id: "text_layout",
  type: "custom_text",
  config: {
    type: "custom_text",
    heading: "Custom Heading",
    body: "Insert Text Here",
  },
  sort: 0,
  enabled: true,
  roundRestrictions: [],
  durationEstimate: 60,
  durationEstimateFirstRound: 60,
  speakerNotes: "",
};

const FAKE_IMAGE_LAYOUT: API.ReportingTemplateLayoutResponse = {
  id: "image_layout",
  type: "custom_image",
  config: {
    type: "custom_image",
    heading: "Custom Heading",
  },
  sort: 0,
  enabled: true,
  roundRestrictions: [],
  durationEstimate: 60,
  durationEstimateFirstRound: 60,
  speakerNotes: "",
};

const FAKE_IMAGE_LEFT_LAYOUT: API.ReportingTemplateLayoutResponse = {
  id: "image_layout",
  type: "custom_text_and_image_left",
  config: {
    type: "custom_text_and_image_left",
    heading: "Custom Heading",
    body: "Insert Text Here",
  },
  sort: 0,
  enabled: true,
  roundRestrictions: [],
  durationEstimate: 60,
  durationEstimateFirstRound: 60,
  speakerNotes: "",
};

const FAKE_IMAGE_RIGHT_LAYOUT: API.ReportingTemplateLayoutResponse = {
  id: "image_layout",
  type: "custom_text_and_image_right",
  config: {
    type: "custom_text_and_image_right",
    heading: "Custom Heading",
    body: "Insert Text Here",
  },
  sort: 0,
  enabled: true,
  roundRestrictions: [],
  durationEstimate: 60,
  durationEstimateFirstRound: 60,
  speakerNotes: "",
};

const FAKE_VIDEO_LAYOUT: API.ReportingTemplateLayoutResponse = {
  id: "video_layout",
  type: "custom_video",
  config: {
    type: "custom_video",
    heading: "Custom Heading",
  },
  sort: 0,
  enabled: true,
  roundRestrictions: [],
  durationEstimate: 60,
  durationEstimateFirstRound: 60,
  speakerNotes: "",
};
const FAKE_BUILDER_LAYOUT: API.ReportingTemplateLayoutResponse = {
  id: "custom_slide_builder",
  type: "custom_slide",
  config: {
    type: "custom_slide",
    heading: "Custom Heading",
    items: [],
  },
  sort: 0,
  enabled: true,
  roundRestrictions: [],
  durationEstimate: 60,
  durationEstimateFirstRound: 60,
  speakerNotes: "",
};

export const AddReportingTemplateLayout: React.FC<Props> = ({
  template,
  onFinish,
  previewData,
  sort,
}) => {
  const [, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const onClick = useCallback(
    (
      type:
        | "custom_text"
        | "custom_image"
        | "custom_video"
        | "custom_text_and_image_left"
        | "custom_text_and_image_right"
        | "custom_slide",
    ) =>
      async (event: any) => {
        event.stopPropagation();
        try {
          let payload: API.ReportingTemplateLayoutCreateRequest;
          if (type === "custom_text") {
            payload = {
              type: "custom_text",
              config:
                FAKE_TEXT_LAYOUT.config as API.ReportingLayoutCustomTextConfig,
              sort,
              enabled: FAKE_TEXT_LAYOUT.enabled,
              roundRestrictions: FAKE_TEXT_LAYOUT.roundRestrictions,
              durationEstimate: FAKE_TEXT_LAYOUT.durationEstimate,
              durationEstimateFirstRound:
                FAKE_TEXT_LAYOUT.durationEstimateFirstRound,
              speakerNotes: FAKE_TEXT_LAYOUT.speakerNotes,
            };
          } else if (type === "custom_image") {
            payload = {
              type: "custom_image",
              config:
                FAKE_IMAGE_LAYOUT.config as API.ReportingLayoutCustomImageConfig,
              sort,
              enabled: FAKE_IMAGE_LAYOUT.enabled,
              roundRestrictions: FAKE_IMAGE_LAYOUT.roundRestrictions,
              durationEstimate: FAKE_IMAGE_LAYOUT.durationEstimate,
              durationEstimateFirstRound:
                FAKE_IMAGE_LAYOUT.durationEstimateFirstRound,
              speakerNotes: FAKE_IMAGE_LAYOUT.speakerNotes,
            };
          } else if (type === "custom_text_and_image_left") {
            payload = {
              type: "custom_text_and_image_left",
              config:
                FAKE_IMAGE_LEFT_LAYOUT.config as API.ReportingLayoutCustomTextAndImageLeftConfig,
              sort,
              enabled: FAKE_IMAGE_LEFT_LAYOUT.enabled,
              roundRestrictions: FAKE_IMAGE_LEFT_LAYOUT.roundRestrictions,
              durationEstimate: FAKE_IMAGE_LEFT_LAYOUT.durationEstimate,
              durationEstimateFirstRound:
                FAKE_IMAGE_LEFT_LAYOUT.durationEstimateFirstRound,
              speakerNotes: FAKE_IMAGE_LEFT_LAYOUT.speakerNotes,
            };
          } else if (type === "custom_text_and_image_right") {
            payload = {
              type: "custom_text_and_image_right",
              config:
                FAKE_IMAGE_RIGHT_LAYOUT.config as API.ReportingLayoutCustomTextAndImageRightConfig,
              sort,
              enabled: FAKE_IMAGE_RIGHT_LAYOUT.enabled,
              roundRestrictions: FAKE_IMAGE_RIGHT_LAYOUT.roundRestrictions,
              durationEstimate: FAKE_IMAGE_RIGHT_LAYOUT.durationEstimate,
              durationEstimateFirstRound:
                FAKE_IMAGE_RIGHT_LAYOUT.durationEstimateFirstRound,
              speakerNotes: FAKE_IMAGE_RIGHT_LAYOUT.speakerNotes,
            };
          } else if (type === "custom_video") {
            payload = {
              type: "custom_video",
              config:
                FAKE_VIDEO_LAYOUT.config as API.ReportingLayoutCustomVideoConfig,
              sort,
              enabled: FAKE_VIDEO_LAYOUT.enabled,
              roundRestrictions: FAKE_VIDEO_LAYOUT.roundRestrictions,
              durationEstimate: FAKE_VIDEO_LAYOUT.durationEstimate,
              durationEstimateFirstRound:
                FAKE_VIDEO_LAYOUT.durationEstimateFirstRound,
              speakerNotes: FAKE_VIDEO_LAYOUT.speakerNotes,
            };
          } else if (type === "custom_slide") {
            payload = {
              type: "custom_slide",
              config:
                FAKE_BUILDER_LAYOUT.config as API.ReportingLayoutCustomConfig,
              sort,
              enabled: FAKE_BUILDER_LAYOUT.enabled,
              roundRestrictions: FAKE_BUILDER_LAYOUT.roundRestrictions,
              durationEstimate: FAKE_BUILDER_LAYOUT.durationEstimate,
              durationEstimateFirstRound:
                FAKE_BUILDER_LAYOUT.durationEstimateFirstRound,
              speakerNotes: FAKE_BUILDER_LAYOUT.speakerNotes,
            };
          } else {
            throw new Error("not supported");
          }

          const newSlide = await API.createReportingTemplateLayout(
            template.id,
            payload,
          );
          onFinish(newSlide);
          setShowModal(false);
        } catch (e) {
          setError(true);
        }
      },
    [template.id, onFinish, sort],
  );

  return (
    <>
      <IconButton
        data-test={`add-layout-${template.id}`}
        icon="copy"
        text="Add Slide"
        onClick={() => {
          setShowModal(true);
        }}
      />
      <SimpleModal
        size="large"
        isOpen={showModal}
        showCloseButton
        onClose={() => setShowModal(false)}
      >
        <Container fit>
          <h2>Choose Layout</h2>
          <VerticalGroup spaceBetweenElements={4}>
            <InlineGroup spaceBetweenElements={6}>
              <Clickable onClick={onClick("custom_text")}>
                <VerticalGroup center spaceBetweenElements={2}>
                  <Text bold>Custom Text</Text>
                  <ReportingPagePreviewTile
                    data={{
                      ...previewData,
                      layouts: [FAKE_TEXT_LAYOUT],
                    }}
                    layout={FAKE_TEXT_LAYOUT}
                    showHeader
                  />
                </VerticalGroup>
              </Clickable>
              <Clickable onClick={onClick("custom_image")}>
                <VerticalGroup center spaceBetweenElements={2}>
                  <Text bold>Custom Image</Text>
                  <ReportingPagePreviewTile
                    data={{
                      ...previewData,
                      layouts: [FAKE_IMAGE_LAYOUT],
                    }}
                    layout={FAKE_IMAGE_LAYOUT}
                    showHeader
                  />
                </VerticalGroup>
              </Clickable>
              <Clickable onClick={onClick("custom_video")}>
                <VerticalGroup center spaceBetweenElements={2}>
                  <Text bold>Custom Video</Text>
                  <ReportingPagePreviewTile
                    data={{
                      ...previewData,
                      layouts: [FAKE_VIDEO_LAYOUT],
                    }}
                    layout={FAKE_VIDEO_LAYOUT}
                    showHeader
                  />
                </VerticalGroup>
              </Clickable>
            </InlineGroup>
            <InlineGroup spaceBetweenElements={6}>
              <Clickable onClick={onClick("custom_text_and_image_left")}>
                <VerticalGroup center spaceBetweenElements={2}>
                  <Text bold>Custom Image And Text (Left)</Text>
                  <ReportingPagePreviewTile
                    data={{
                      ...previewData,
                      layouts: [FAKE_IMAGE_LEFT_LAYOUT],
                    }}
                    layout={FAKE_IMAGE_LEFT_LAYOUT}
                    showHeader
                  />
                </VerticalGroup>
              </Clickable>
              <Clickable onClick={onClick("custom_text_and_image_right")}>
                <VerticalGroup center spaceBetweenElements={2}>
                  <Text bold>Custom Image And Text (Right)</Text>
                  <ReportingPagePreviewTile
                    data={{
                      ...previewData,
                      layouts: [FAKE_IMAGE_RIGHT_LAYOUT],
                    }}
                    layout={FAKE_IMAGE_RIGHT_LAYOUT}
                    showHeader
                  />
                </VerticalGroup>
              </Clickable>
              <Clickable onClick={onClick("custom_slide")}>
                <VerticalGroup center spaceBetweenElements={2}>
                  <Text bold>Custom Builder</Text>
                  <ReportingPagePreviewTile
                    data={{
                      ...previewData,
                      layouts: [FAKE_BUILDER_LAYOUT],
                    }}
                    layout={FAKE_BUILDER_LAYOUT}
                    showHeader
                  />
                </VerticalGroup>
              </Clickable>
            </InlineGroup>
          </VerticalGroup>
        </Container>
      </SimpleModal>
    </>
  );
};
