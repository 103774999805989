import React, { useEffect } from "react";
import classNames from "classnames";
import {
  Column,
  useSortBy,
  useTable,
  PluginHook,
  SortingRule,
} from "react-table";
import Table, { Props as TableProps } from "../../atoms/table/Table";
import Icon from "../../atoms/icon/Icon";
import "./Sortable-table.scss";
import { useHotkeys } from "react-hotkeys-hook";
import { createRowSelectPlugin } from "./plugins";
import useIsMobileOrTablet from "../../../hooks/useIsMobileOrTablet";

interface Props<T extends object> extends TableProps {
  columns: Array<Column<T> & { hidden?: boolean }>;
  data: Array<any>;
  selectedRowsCallback?: (selectedRows: T[]) => void;
  onRowClick?: (
    event: { stopPropagation: () => void; preventDefault: () => void },
    row: { original: T },
  ) => any;
  showCheckboxes?: boolean;
  defaultSortBy?: SortingRule<API.ParticipantResponse>[];
}

function SortableTable<T extends object>({
  columns,
  data,
  selectedRowsCallback,
  onRowClick,
  showCheckboxes = true,
  defaultSortBy,
  ...rest
}: Props<T>) {
  const isMobileOrTablet = useIsMobileOrTablet();
  const plugins: PluginHook<T>[] = [useSortBy];
  if (selectedRowsCallback && showCheckboxes) {
    plugins.push(...createRowSelectPlugin(showCheckboxes));
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        sortBy: defaultSortBy || [],
      },
    },
    ...plugins,
  );

  useEffect(() => {
    if (selectedRowsCallback && selectedFlatRows) {
      selectedRowsCallback(selectedFlatRows.map((row) => row.original));
    }
  }, [selectedFlatRows, selectedRowsCallback]);

  useHotkeys("ctrl+a, command+a", (event) => {
    event.preventDefault();
    toggleAllRowsSelected();
  });

  return (
    <Table
      {...getTableProps()}
      {...rest}
      className={classNames("sortable-table", { mobile: isMobileOrTablet })}
    >
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={i}>
            {headerGroup.headers.map((column: any) => {
              if (column.hidden) return null;
              return (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <Icon type="arrow" className="arrowDesc" />
                      ) : (
                        <Icon type="arrow" className="arrowAsc" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row: any) => {
          prepareRow(row);
          if (row.original.hidden) {
            return null;
          }
          return (
            <tr
              key={row.original.id}
              {...row.getRowProps()}
              data-test={row.original.id}
            >
              {row.cells.map(
                (
                  cell: {
                    getCellProps: () => JSX.IntrinsicAttributes &
                      React.ClassAttributes<HTMLTableDataCellElement> &
                      React.TdHTMLAttributes<HTMLTableDataCellElement>;
                    render: (arg0: string) => React.ReactNode;
                    column: { hidden?: boolean };
                  },
                  index: number,
                ) => {
                  if (cell.column.hidden) return null;
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps()}
                      onClick={(event: {
                        stopPropagation: () => void;
                        preventDefault: () => void;
                      }) =>
                        onRowClick && index ? onRowClick(event, row) : null
                      }
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                },
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default SortableTable;
