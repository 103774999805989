import API from "../../../../services/api";
import { convertToYYYYMMDD } from "../../../../lib/date";

export const getEventUpdatePayload = (
  event: API.EventRequest,
  overrides: Partial<API.EventRequest>,
): API.EventRequest => {
  const {
    date,
    clientId,
    brandingId,
    simulationId,
    reportingTemplateId,
    assessmentId,
    name,
    location,
    streamName,
    maskParticipants,
    schedule,
  } = event;

  return {
    date: convertToYYYYMMDD(new Date(date)),
    maskParticipants,
    clientId,
    brandingId,
    simulationId,
    reportingTemplateId,
    assessmentId,
    name: name.trim(),
    location: location.trim(),
    streamName: streamName?.trim(),
    schedule,
    ...overrides,
  };
};
