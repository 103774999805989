import React, { useCallback, useEffect } from "react";
import API from "../../../services/api";
import useAPIRequest from "../../../hooks/useAPIRequest";
import ReportingListTable from "./ReportingTemplatesListTable";
import Container from "../../atoms/page/Page";
import LoadingSpinner from "../../atoms/loadingspinner/LoadingSpinner";
import Banner from "../../atoms/banner/Banner";

function ReportingTemplateList() {
  const callback = useCallback(() => {
    return API.getReportingTemplates();
  }, []);

  const [{ inProgress, completed, data, error }, doAPIRequest] = useAPIRequest<
    API.ReportingTemplateResponse[]
  >(callback, { data: [] });

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const handleRefreshTemplatess = useCallback(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return (
    <Container>
      {inProgress && <LoadingSpinner />}
      {error && (
        <Banner type="error" active={!!error} message={error?.message} />
      )}
      {completed && data && (
        <ReportingListTable
          templates={data}
          refreshTemplates={handleRefreshTemplatess}
        />
      )}
    </Container>
  );
}

export default ReportingTemplateList;
