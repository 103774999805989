import React from "react";
import classNames from "classnames";

import "./VerticalGroup.scss";
import { ThemeColours, ThemeSpacing } from "../../../types/theme";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  bottom?: boolean;
  bgColour?: ThemeColours;
  spread?: boolean;
  className?: string;
  verticalCenter?: boolean;
  full?: boolean;
  half?: boolean;
  center?: boolean;
  reverse?: boolean;
  wide?: boolean;
  flexAuto?: boolean;
  spaceBetweenElements?: ThemeSpacing;
  borderLeft?: boolean;
  borderColour?: ThemeColours;
  curved?: boolean;
}

const VerticalGroup = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      bgColour,
      bottom,
      spread,
      verticalCenter,
      full,
      half,
      wide,
      center,
      reverse,
      spaceBetweenElements,
      className,
      flexAuto,
      borderColour,
      borderLeft,
      curved,
      ...rest
    },
    ref,
  ) => {
    VerticalGroup.displayName = "VerticalGroup";
    const spaceBetweenElementsClass = spaceBetweenElements
      ? `space-between-${spaceBetweenElements}`
      : null;
    const bgColourClass = bgColour ? `bg-colour-${bgColour}` : null;
    const borderColourClass = borderColour
      ? `border-colour-${borderColour}`
      : null;
    return (
      <div
        ref={ref}
        className={classNames(
          "vertical-group",
          {
            bottom,
            spread,
            full,
            half,
            "align-center": center,
            "border-left": borderLeft,
            center: verticalCenter,
            reverse,
            wide,
            flexAuto,
            curved,
          },
          spaceBetweenElementsClass,
          className,
          bgColourClass,
          borderColourClass,
        )}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

export default VerticalGroup;
