import React, { MouseEvent } from "react";
import classNames from "classnames";

import InputContainer from "./InputContainer";

import "./Input.scss";
import InlineGroup from "../../inlinegroup/InlineGroup";
import { useCallback } from "react";
import { useRef } from "react";
import Button from "../../button/Button";
import Text from "../../text/Text";

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement>,
    React.ComponentProps<typeof InputContainer> {
  allowLastPass?: boolean;
  value?: number;
}

const NumberInput: React.FC<Props> = ({
  className,
  noBorder,
  label,
  labelFor,
  error,
  block,
  containerBlock,
  autoFocus,
  large,
  small,
  reverse,
  right,
  helpTitle,
  helpDescription,
  value = 0,
  ...rest
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onChange = rest.onChange;
  const { disabled } = rest;

  const addValue = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (inputRef.current) {
        const inputEl = inputRef.current;
        inputEl.value = String(Number(inputEl.value) + 1);
        onChange &&
          onChange({
            ...e,
            target: {
              ...e.target,
              value: inputEl.value,
            } as any,
          } as any);
      }
    },
    [onChange],
  );

  const reduceValue = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (inputRef.current) {
        const inputEl = inputRef.current;
        inputEl.value = String(Number(inputEl.value) - 1);
        onChange &&
          onChange({
            ...e,
            target: {
              ...e.target,
              value: inputEl.value,
            } as any,
          } as any);
      }
    },
    [onChange],
  );

  return (
    <InputContainer
      className={classNames(className, { block })}
      noBorder={noBorder}
      label={label}
      labelFor={labelFor}
      helpDescription={helpDescription}
      helpTitle={helpTitle}
      error={error}
      reverse={reverse}
      right={right}
      containerBlock={containerBlock}
    >
      <InlineGroup>
        <Button
          disabled={Number(value) <= 0 || disabled}
          className="number-input-button"
          mini
          onClick={reduceValue}
        >
          <Text bold size="2xl">
            -
          </Text>
        </Button>
        <input
          ref={inputRef}
          autoFocus={autoFocus}
          className={classNames("input", "number-input", {
            invalid: error,
            block,
            large,
            small,
          })}
          type="number"
          value={value}
          {...rest}
        />
        <Button
          className="number-input-button"
          mini
          onClick={addValue}
          disabled={disabled}
        >
          <Text bold size="2xl">
            +
          </Text>
        </Button>
      </InlineGroup>
    </InputContainer>
  );
};

export default NumberInput;
