import { useCallback, useEffect, useMemo } from "react";
import ModelAPI from "../services/modelApi";
import useAPIRequest from "./useAPIRequest";
import { PreviewSettings } from "../components/pages/administration/configuration/economicScenarioBuilder/types";

export const usePreviewReportingData = (
  reportingTemplateId: string,
  simulationId: string,
  roundId: number,
  brandingId?: string,
) => {
  const callback = useCallback(() => {
    return ModelAPI.getPreviewData(
      reportingTemplateId,
      simulationId,
      roundId,
      brandingId,
    );
  }, [brandingId, reportingTemplateId, roundId, simulationId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<ModelAPI.Reporting.ReportingResults>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

export const useGenericPreviewReportingData = (
  reportingTemplateId: string | undefined | null,
  roundId: number,
  scenarioId?: string,
  previewSettings?: PreviewSettings,
) => {
  const callback = useCallback(() => {
    if (!roundId) return Promise.resolve(null);
    return ModelAPI.getGenericPreviewData(
      reportingTemplateId,
      roundId,
      scenarioId,
      previewSettings,
    );
  }, [reportingTemplateId, roundId, scenarioId, previewSettings]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<ModelAPI.Reporting.ReportingResults | null>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  return useMemo(
    () => ({
      inProgress,
      data,
      error,
      refresh: doAPIRequest,
    }),
    [inProgress, data, error, doAPIRequest],
  );
};

export default usePreviewReportingData;
