import React from "react";

import classNames from "classnames";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";

import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Text from "../../../atoms/text/Text";

import "./Sidebar.scss";
import Clickable from "../../../atoms/clickable/Clickable";
import { Module } from "./Configuration";
interface Props {
  selected: string;
  onChange: (moduleId: string) => void;
  modules: Module[];
  displayItems: Module[];
}

function ConfigurationSidebar({
  selected,
  onChange,
  modules,
  displayItems,
}: Props) {
  return (
    <div className="configurationsidebar">
      <VerticalGroup wide>
        <InlineGroup className="pl-2 pr-4 pt-4 pb-2">
          <Text colour="green" size="lg" center>
            Modules
          </Text>
        </InlineGroup>
        <VerticalGroup wide>
          {modules.map((m) => (
            <Clickable
              onClick={() => onChange(m.id)}
              className={classNames("configurationsidebarmodule", {
                active: m.id === selected,
              })}
              key={m.id}
            >
              <VerticalGroup spread wide spaceBetweenElements={2}>
                <InlineGroup verticalCenter center>
                  <Text size="sm" medium>
                    {m.name}
                  </Text>
                </InlineGroup>
              </VerticalGroup>
            </Clickable>
          ))}
        </VerticalGroup>
        <InlineGroup className="pl-2 pr-4 pt-4 pb-2">
          <Text colour="green" size="lg" center>
            Display
          </Text>
        </InlineGroup>
        <VerticalGroup wide>
          {displayItems.map((m) => (
            <Clickable
              onClick={() => onChange(m.id)}
              className={classNames("configurationsidebarmodule", {
                active: m.id === selected,
              })}
              key={m.id}
            >
              <VerticalGroup spread wide spaceBetweenElements={2}>
                <InlineGroup verticalCenter center>
                  <Text size="sm" medium>
                    {m.name}
                  </Text>
                </InlineGroup>
              </VerticalGroup>
            </Clickable>
          ))}
        </VerticalGroup>
      </VerticalGroup>
    </div>
  );
}

export default ConfigurationSidebar;
