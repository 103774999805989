import React, { useCallback, useState } from "react";
import SimpleModal from "../../../organisms/standard-modal/SimpleModal";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Button from "../../../atoms/button/Button";
import Text from "../../../atoms/text/Text";
import modelApi from "../../../../services/modelApi";
import api from "../../../../services/api";
import SimulationDropdown from "../../../organisms/simulation-dropdown/SimulationDropdown";
import IconButton from "../../../molecules/iconbutton/IconButton";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";

interface Props {
  reportingTemplateId: string;
  onFinish: () => void;
}

export const AutoConfigure: React.FC<Props> = ({
  reportingTemplateId,
  onFinish,
}) => {
  const [, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inProgress, setInProgres] = useState(false);
  const [simulationId, setSimulationId] = useState<string | null>(null);

  const onClick = useCallback(
    async (event: any) => {
      event.stopPropagation();
      if (!simulationId) {
        return;
      }
      try {
        setInProgres(true);
        const layouts = await modelApi.getDefaultLayouts(
          reportingTemplateId,
          simulationId,
        );
        for (let i = 0; i < layouts.length; i++) {
          const val = layouts[i];
          if (
            val.type === "custom_image" &&
            val.config &&
            val.config.type === "custom_image"
          ) {
            delete val.config.imageUrl;
          }
          if (
            val.type === "custom_text_and_image_left" &&
            val.config &&
            val.config.type === "custom_text_and_image_left"
          ) {
            delete val.config.imageUrl;
          }
          if (
            val.type === "custom_text_and_image_right" &&
            val.config &&
            val.config.type === "custom_text_and_image_right"
          ) {
            delete val.config.imageUrl;
          }
          if (
            val.type === "custom_video" &&
            val.config &&
            val.config.type === "custom_video"
          ) {
            delete val.config.videoUrl;
          }
        }
        await api.patchReportingTemplate(reportingTemplateId, { layouts });
        onFinish();
        setShowModal(false);
        setInProgres(false);
      } catch (e) {
        setError(true);
        setInProgres(false);
      }
    },
    [onFinish, reportingTemplateId, simulationId],
  );

  return (
    <>
      <IconButton
        data-test="auto-configure"
        icon="copy"
        text="Auto Configure"
        onClick={() => {
          setShowModal(true);
        }}
      />
      <SimpleModal
        size="small"
        isOpen={showModal}
        showCloseButton
        onClose={() => setShowModal(false)}
      >
        <VerticalGroup spaceBetweenElements={4}>
          <Text size="sm" bold>
            Simulation
          </Text>
          <SimulationDropdown
            simulationId={simulationId ?? undefined}
            onSimulationSelected={(simulation: API.SimulationResponse) =>
              setSimulationId(simulation.id)
            }
          />
        </VerticalGroup>
        <InlineGroup className="mt-4" spread spaceBetweenElements={2}>
          <Button
            data-test="modal-cancel"
            light
            onClick={() => setShowModal(false)}
            large
          >
            Cancel
          </Button>
          <InlineGroup right spaceBetweenElements={2}>
            <Button
              data-test="modal-confirm"
              onClick={onClick}
              inProgress={inProgress}
              disabled={inProgress}
              large
            >
              Generate
            </Button>
          </InlineGroup>
        </InlineGroup>
      </SimpleModal>
    </>
  );
};
