import React, { useState, useEffect, useMemo } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsTable from "../table/ResultsTable";
import Image from "../../../atoms/image/Image";
import ArcherMan from "../../../../assets/results/ArcherMan.png";
import CourtRoomAngry from "../../../../assets/results/CourtRoomAngry.png";
import { TeamsWithColours } from "../RoundResultsPage";
import { thousandSeparator } from "../../model/utils/formatters";
import "../RoundResultsTablePage.scss";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";

interface Props {
  teams: TeamsWithColours[];
  riskIncidentsClosing: ModelAPI.Reporting.RiskIncidentsClosing;
  currencyDenomination: string;
}

const RoundResultsRiskIncidentsClosing: React.FC<Props> = ({
  riskIncidentsClosing,
  teams,
  currencyDenomination,
}) => {
  const [show, setShow] = useState(false);

  const { sortedCapitalFromHighestToLowest } = useMemo(() => {
    const sortedCapitalFromHighestToLowest = riskIncidentsClosing.data
      .filter((a) => a.incident > 0)
      .sort((a, b) => b.incident - a.incident);
    return { sortedCapitalFromHighestToLowest };
  }, [riskIncidentsClosing.data]);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 2000);

    return () => clearTimeout(timer);
  }, [show]);

  return (
    <RoundResultsContainer>
      {sortedCapitalFromHighestToLowest.length === 0 ? (
        <VerticalGroup full center wide>
          <EditableReportingHeading defaultHeading="Breaking News!" />
          <ResultsText size="xl" bold colour="blue" center>
            All banks have made good progress on non-financial risk management.
          </ResultsText>
          <InlineGroup fullHeight center verticalCenter>
            <Image
              src={ArcherMan}
              alt="Archor Man"
              style={{ width: "55%" }}
            ></Image>
          </InlineGroup>
        </VerticalGroup>
      ) : (
        <VerticalGroup full center wide spaceBetweenElements={2}>
          <EditableReportingHeading defaultHeading="Breaking News!" />
          <ResultsText size="xl" bold colour="blue" center className="mb-2">
            Regulatory imposes capital penalty on banks with insufficent risk
            management process.
          </ResultsText>
          <InlineGroup spread fullHeight>
            <VerticalGroup spread style={{ width: "100%", height: "100%" }}>
              <VerticalGroup>
                <InlineGroup block center>
                  <ResultsText size="lg" bold>
                    Penalty Detail
                  </ResultsText>
                </InlineGroup>
                <VerticalGroup>
                  <ResultsText size="md" className="mb-2 mt-4 pr-2">
                    The Banking regulator has levied an additional &quot;$
                    {thousandSeparator(riskIncidentsClosing.capital)}&quot;
                    capital required on each bank that has not been able to
                    reduce unresolved risk incidents below &quot;$
                    {thousandSeparator(
                      riskIncidentsClosing.risk_incidents_below,
                    )}
                    .&quot;
                  </ResultsText>
                  <ResultsText size="lg" className="pr-10">
                    When banks are able to reduce incidents below.
                  </ResultsText>
                </VerticalGroup>
              </VerticalGroup>
              <InlineGroup block verticalCenter center>
                <Image
                  style={{ maxWidth: "90%" }}
                  src={CourtRoomAngry}
                  alt="Court Room"
                ></Image>
              </InlineGroup>
            </VerticalGroup>
            <VerticalGroup
              style={{ width: "100%", height: "100%" }}
              className={show ? "show-options" : "no-show-options"}
            >
              <ResultsTable id="999">
                <thead>
                  <tr>
                    <th>BANK</th>
                    <th>INCIDENTS #</th>
                    <th>+ CAPITAL ({currencyDenomination})</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedCapitalFromHighestToLowest.map((cap, i) => {
                    const teamForTable = teams.find(
                      (t) => t.teamId === cap.teamId,
                    );

                    return (
                      <tr
                        key={i}
                        style={{
                          animationDelay: `${sortedCapitalFromHighestToLowest.length - i}s`,
                        }}
                      >
                        <td style={{ color: `${teamForTable?.colour}` }}>
                          {teamForTable?.teamName}
                        </td>
                        <td>{thousandSeparator(cap.incident)}</td>
                        <td>
                          {thousandSeparator(riskIncidentsClosing.capital)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ResultsTable>
            </VerticalGroup>
          </InlineGroup>
        </VerticalGroup>
      )}
    </RoundResultsContainer>
  );
};

export default RoundResultsRiskIncidentsClosing;
