import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import API from "../../../../services/api";
import useAPIRequest from "../../../../hooks/useAPIRequest";
import Container from "../../../atoms/page/Page";
import Input from "../../../atoms/form/input/Input";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import LoadingSpinner from "../../../atoms/loadingspinner/LoadingSpinner";
import AccountToggle from "./UserToggle";
import UserDelete from "./UserDelete";
import ActivateUser from "./ActivateUser";
import DisplayField from "../../../atoms/form/display-field/DisplayField";
import Form from "../../../atoms/form/Form";
import Banner from "../../../atoms/banner/Banner";
import IconButton from "../../../molecules/iconbutton/IconButton";
import api from "../../../../services/api";
import { formatUserType } from "./lib/formatters";
import ClientAvatarList from "../../../molecules/client-avatar-list/ClientAvatarList";

interface UserState {
  accountToggleModalOpen: boolean;
  deleteModalOpen: boolean;
  infoMessage?: string;
}

function UserDetails() {
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();

  const callback = useCallback(() => {
    return API.getUser(userId);
  }, [userId]);

  const [{ inProgress, data, error }, doAPIRequest] =
    useAPIRequest<API.UserResponse>(callback, null);

  useEffect(() => {
    doAPIRequest();
  }, [doAPIRequest]);

  const [
    { accountToggleModalOpen, deleteModalOpen, infoMessage },
    setUserState,
  ] = useState<UserState>({
    accountToggleModalOpen: false,
    deleteModalOpen: false,
    infoMessage: "",
  });

  const onAccountToggleComplete = useCallback(() => {
    setUserState((prevState) => ({
      ...prevState,
      accountToggleModalOpen: false,
    }));
    history.push("/administration/users");
  }, [history]);

  const onUserDeleteComplete = useCallback(() => {
    setUserState((prevState) => ({ ...prevState, deleteModalOpen: false }));
    history.push("/administration/users");
  }, [history]);

  const sendResetPasswordEmail = useCallback(async (email) => {
    await api.sendResetPasswordEmail({ email });
    setUserState((prevState) => ({
      ...prevState,
      infoMessage: `Reset password email sent to ${email}`,
    }));
  }, []);

  const resendVerificationEmail = useCallback(async (userId, email) => {
    await api.resendVerificationEmail(userId);
    setUserState((prevState) => ({
      ...prevState,
      infoMessage: `Invitation email resent to ${email}`,
    }));
  }, []);

  return (
    <>
      {accountToggleModalOpen && !!data && (
        <AccountToggle
          isOpen={accountToggleModalOpen}
          onClose={() =>
            setUserState((prevState) => ({
              ...prevState,
              accountToggleModalOpen: false,
            }))
          }
          onComplete={onAccountToggleComplete}
          user={data}
        />
      )}
      {deleteModalOpen && !!data && (
        <UserDelete
          isOpen={deleteModalOpen}
          onClose={() =>
            setUserState((prevState) => ({
              ...prevState,
              deleteModalOpen: false,
            }))
          }
          onComplete={onUserDeleteComplete}
          user={data}
        />
      )}

      <Container className="user-edit">
        {error && (
          <Banner type="error" active={!!error} message={error?.message} />
        )}
        {infoMessage && (
          <Banner
            type="success"
            active
            message={infoMessage}
            onClose={() =>
              setUserState((prevState) => ({ ...prevState, infoMessage: "" }))
            }
          />
        )}
        <InlineGroup spread verticalCenter>
          <h2>User Details</h2>
          {!!data && !inProgress && (
            <InlineGroup spread spaceBetweenElements={2}>
              {data.verified && (
                <IconButton
                  data-test={`reset-password-${userId}`}
                  icon="mail"
                  text="Reset Password"
                  onClick={() => sendResetPasswordEmail(data.email)}
                />
              )}
              {!data.verified && (
                <IconButton
                  data-test={`resend-invite-${userId}`}
                  icon="mail"
                  text="Resend Invite"
                  onClick={() => resendVerificationEmail(data.id, data.email)}
                />
              )}
              <IconButton
                data-test={`edit-user-${userId}`}
                icon="edit"
                text="Edit User"
                linkTo={`/administration/users/${userId}/edit`}
              />
              {data.state === "active" ? (
                <IconButton
                  data-test={`toggle-user-state-${data.id}`}
                  icon="ban"
                  text="Deactivate User"
                  onClick={() =>
                    setUserState((prevState) => ({
                      ...prevState,
                      accountToggleModalOpen: true,
                    }))
                  }
                />
              ) : (
                <ActivateUser user={data} />
              )}
              <IconButton
                danger
                data-test={`delete-user-${data.id}`}
                icon="trash"
                text="Delete"
                onClick={() =>
                  setUserState((prevState) => ({
                    ...prevState,
                    deleteModalOpen: true,
                  }))
                }
              />
            </InlineGroup>
          )}
        </InlineGroup>
        {inProgress && <LoadingSpinner />}
        {!!data && !inProgress && (
          <>
            <Form>
              <Input
                data-test="email"
                type="text"
                value={data.email}
                label="Email"
                readOnly
              />
              <Input
                data-test="firstName"
                type="text"
                value={data.firstName}
                label="First Name"
                readOnly
              />
              <Input
                data-test="lastName"
                type="text"
                value={data.lastName}
                label="Last Name"
                readOnly
              />
              <Input
                data-test="type"
                type="text"
                value={formatUserType(data.type)}
                label="Account Type"
                readOnly
              />
              {!["superadmin", "facilitator"].includes(data.type) && (
                <DisplayField
                  stretch
                  label="Clients"
                  value={<ClientAvatarList clients={data.clients} />}
                />
              )}
              <DisplayField label="User ID" value={data.id} />
            </Form>
          </>
        )}
      </Container>
    </>
  );
}

export default UserDetails;
