import React, { useCallback } from "react";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import ResultsText from "../results-text/ResultsText";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Icon from "../../../atoms/icon/Icon";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import Card from "../../../atoms/card/Card";
import "./RoundResultsKeyOutcomes.scss";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useResultsContext } from "../context/context";

export const keyOutcomesMaxClicks = 3;

interface Props {}

const RoundResultsKeyOutcomes: React.FC<Props> = () => {
  const isMobile = useIsMobile();
  const context = useResultsContext();

  const onNext = useCallback(() => {
    if (context.clicks < keyOutcomesMaxClicks) {
      context.addClick();
    } else {
      context.goNextPage();
    }
  }, [context]);

  const renderSection = (sectionNumber: number) => {
    return context.noAnimations || context.clicks >= sectionNumber;
  };

  return (
    <RoundResultsContainer onNext={onNext}>
      <Card fullHeight sharp>
        <ResultsText bold size="2xl" center>
          Key Program Outcomes
        </ResultsText>
        <VerticalGroup className="RoundResultsKeyOutcomes" center full>
          <div
            style={{ width: isMobile ? "100%" : "92%" }}
            className="grid-container"
          >
            <div className="first-column">
              <VerticalGroup full verticalCenter>
                <VerticalGroup full>
                  <InlineGroup spaceBetweenElements={4} block verticalTop right>
                    <VerticalGroup spaceBetweenElements={4}>
                      <ResultsText bold right>
                        Increase banking business acumen
                      </ResultsText>
                      <ResultsText right>
                        Understanding the rippling impact of decisions
                      </ResultsText>
                    </VerticalGroup>
                    <InlineGroup>
                      <Icon
                        dynamicSize={{
                          min: "40px",
                          preferred: "8vw",
                          max: "900px",
                        }}
                        noMargin
                        type="outcomeOne"
                      />
                    </InlineGroup>
                  </InlineGroup>
                </VerticalGroup>

                {renderSection(3) && (
                  <VerticalGroup full className="bottom-outcome">
                    <InlineGroup
                      spaceBetweenElements={4}
                      block
                      verticalTop
                      right
                      style={{ maxHeight: "50%" }}
                    >
                      <VerticalGroup spaceBetweenElements={4}>
                        <ResultsText bold right>
                          Manage banking trade offs
                        </ResultsText>
                        <ResultsText right>
                          Manage the complex trade-offs between various
                          stakeholder outcomes
                        </ResultsText>
                      </VerticalGroup>
                      <InlineGroup>
                        <Icon
                          dynamicSize={{
                            min: "40px",
                            preferred: "8vw",
                            max: "900px",
                          }}
                          noMargin
                          type="outcomeThree"
                        />
                      </InlineGroup>
                    </InlineGroup>
                  </VerticalGroup>
                )}
              </VerticalGroup>
            </div>
            <InlineGroup center verticalCenter className="second-column">
              <Icon
                dynamicSize={{ min: "150px", preferred: "24vw", max: "900px" }}
                type="outcomeRocket"
                noMargin
              />
            </InlineGroup>
            <div className="third-column">
              <VerticalGroup full verticalCenter>
                {renderSection(1) && (
                  <VerticalGroup full>
                    <InlineGroup spaceBetweenElements={4} block verticalTop>
                      <InlineGroup>
                        <Icon
                          dynamicSize={{
                            min: "40px",
                            preferred: "8vw",
                            max: "900px",
                          }}
                          noMargin
                          type="outcomeTwo"
                        />
                      </InlineGroup>
                      <VerticalGroup spaceBetweenElements={4}>
                        <ResultsText bold>
                          Think long-term and holistically
                        </ResultsText>
                        <ResultsText>
                          Make the links between products areas, and front and
                          vital back offices functions
                        </ResultsText>
                      </VerticalGroup>
                    </InlineGroup>
                  </VerticalGroup>
                )}
                {renderSection(2) && (
                  <VerticalGroup full className="bottom-outcome">
                    <InlineGroup
                      spaceBetweenElements={4}
                      block
                      verticalTop
                      right
                      style={{ maxHeight: "50%" }}
                    >
                      <InlineGroup>
                        <Icon
                          dynamicSize={{
                            min: "40px",
                            preferred: "8vw",
                            max: "900px",
                          }}
                          noMargin
                          type="outcomeFour"
                        />
                      </InlineGroup>
                      <VerticalGroup spaceBetweenElements={4}>
                        <ResultsText bold>
                          Collaborate & challenge in teams
                        </ResultsText>
                        <ResultsText>
                          Learn in a shared learning environment, while having
                          lots of fun
                        </ResultsText>
                      </VerticalGroup>
                    </InlineGroup>
                  </VerticalGroup>
                )}
              </VerticalGroup>
            </div>
          </div>
        </VerticalGroup>
      </Card>
    </RoundResultsContainer>
  );
};

export default RoundResultsKeyOutcomes;
