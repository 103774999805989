import React, { useCallback, useState } from "react";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import Image from "../../../atoms/image/Image";
import Card from "../../../atoms/card/Card";
import { FileDropZone } from "../../../organisms/file-dropzone/FileDropZone";

import "../RoundResultsTablePage.scss";
import { useResultsContext } from "../context/context";
import { toWebpBlobs } from "../../../../lib/images";
import API from "../../../../services/api";
import InlineError from "../../../atoms/inlineerror/InlineError";
import RoundResultsContainer from "../components/RoundsResultsContainer";
import EditableReportingHeading from "../components/EditableReportingHeading";

interface Props {
  data: API.ReportingLayoutCustomImageConfig;
}

const RoundResultsCustomImage: React.FC<Props> = ({ data }) => {
  const context = useResultsContext();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isUploading, setUploading] = useState(false);
  const onChange = useCallback(
    (val: API.ReportingLayoutCustomImageConfig) => {
      if (context.onCustomTemplateEdit) {
        context.onCustomTemplateEdit({
          config: val,
        });
      }
    },
    [context],
  );

  const onDrop = useCallback(
    (files: File[]) => {
      (async () => {
        setUploading(true);
        setUploadError(null);

        const newFile = await toWebpBlobs(files[0]);
        if (newFile && newFile.size > 6000000) {
          setUploadError(
            "File size must <= 10M, please try again with a smaller image",
          );
          setUploading(false);
          return;
        }

        if (newFile) {
          try {
            const image = await API.uploadReportingImage(newFile);
            onChange({
              ...data,
              imageId: image.id,
            });
            setUploading(false);
            setUploadError(null);
          } catch (e) {
            setUploading(false);
            setUploadError("something went wrong when uploading file");
          }
        }
      })();
    },
    [data, onChange],
  );

  return (
    <RoundResultsContainer>
      <VerticalGroup full center wide>
        <EditableReportingHeading />
        <InlineGroup fullHeight block>
          <Card wide sharp padding={2}>
            <InlineGroup fullHeight block center>
              {data.imageUrl && (
                <Image
                  width={"100%"}
                  height={"90%"}
                  src={data.imageUrl}
                  alt="Custom Image"
                  contain
                />
              )}
              {!data.imageId &&
                !data.imageUrl &&
                context.allowCustomTemplateEdits && (
                  <VerticalGroup wide full>
                    <FileDropZone
                      required={false}
                      className="width-100-percent"
                      onDrop={onDrop}
                      accept="image/*"
                      dropzonePlaceholderMain="Drag your file here"
                      dropzonePlaceholderSecondary="or click to upload from your computer"
                      isLoading={isUploading}
                      fullHeight
                    />
                    {uploadError && (
                      <InlineError active message={uploadError} />
                    )}
                  </VerticalGroup>
                )}
              {!data.imageId &&
                !data.imageUrl &&
                !context.allowCustomTemplateEdits && (
                  <Image
                    src="https://via.placeholder.com/728x500.png?text=Add+Your+Custom+Image"
                    alt="Custom Video"
                    contain
                    dynamicSize={{
                      min: "400px",
                      preferred: "85vw",
                      max: "1500px",
                    }}
                  />
                )}
            </InlineGroup>
          </Card>
        </InlineGroup>
      </VerticalGroup>
    </RoundResultsContainer>
  );
};

export default RoundResultsCustomImage;
