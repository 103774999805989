import React from "react";

import Card from "../../../atoms/card/Card";
import VerticalGroup from "../../../atoms/verticalgroup/VerticalGroup";
import InlineGroup from "../../../atoms/inlinegroup/InlineGroup";
import ResultsTable from "../table/ResultsTable";
import ArrowHeader from "../components/ArrowHeader";
import { TeamsWithColours } from "../RoundResultsPage";

import Theme from "../../../../styles/_theme.module.scss";

import "../RoundResultsTablePage.scss";
import ResultsText from "../results-text/ResultsText";
import DynamicResultsText from "../results-text/DynamicResultsText";
import EditableReportingHeading from "../components/EditableReportingHeading";

interface Props {
  teams: TeamsWithColours[];
  executiveDecisions: ModelAPI.Reporting.ExcoDecision;
  showDescription: boolean;
}

const getDotColour = (idx: number) => {
  switch (idx) {
    case 0:
      return Theme.yellow;
    case 1:
      return Theme.green;
    case 2:
      return Theme.blue;
    default:
      return "blue";
  }
};

const RoundResultsExecutiveDecisionsTableTwo: React.FC<Props> = ({
  executiveDecisions,
  teams,
  showDescription,
}) => {
  const maxNumberOfRows = Math.max(
    ...executiveDecisions.options!.map((o) => o.teams.length),
  );

  return (
    <VerticalGroup full center wide>
      <EditableReportingHeading defaultHeading="Risk Forum" />
      <ResultsText size="xl" className="mb-4" bold colour="blue">
        The right answer is sometimes unclear
      </ResultsText>
      <InlineGroup fullHeight>
        <Card wide sharp padding={0} className="executive-decisions-card">
          <InlineGroup spread fullHeight>
            <VerticalGroup
              wide
              className={showDescription ? "show-bids pt-6" : "no-show-options"}
            >
              <ArrowHeader
                text={executiveDecisions.name}
                colour="primaryDark"
              />
              <VerticalGroup full className="mt-4">
                {executiveDecisions.options?.map((option, i) => (
                  <InlineGroup block key={i} className="pl-8 pr-4" fullHeight>
                    <VerticalGroup center>
                      <div
                        className="options-dot mr-4"
                        style={{ backgroundColor: getDotColour(i) }}
                      ></div>
                      {i >= 2 ? (
                        <span className="dot-separator mr-4 mt-1" />
                      ) : (
                        <span className="dot-separator mr-4 mt-1 mb-1" />
                      )}
                    </VerticalGroup>
                    <VerticalGroup>
                      <InlineGroup block verticalCenter>
                        <ResultsText size="md" bold>
                          {option.name}
                        </ResultsText>
                      </InlineGroup>
                      <DynamicResultsText size="sm" className="pb-4" isHtml>
                        {option.outcome}
                      </DynamicResultsText>
                      {option.impacts && option.impacts.length > 0 && (
                        <DynamicResultsText size="sm" className="pb-4" isHtml>
                          {option.impacts
                            .map((impact) => `${impact.name} ${impact.impact}`)
                            .join(". ")}
                        </DynamicResultsText>
                      )}
                    </VerticalGroup>
                  </InlineGroup>
                ))}
              </VerticalGroup>
            </VerticalGroup>
            <VerticalGroup wide full className="p-8">
              <ResultsTable fadeIn id={executiveDecisions.id}>
                <thead>
                  <tr>
                    {executiveDecisions.options?.map((option, i) => (
                      <th key={i} style={{ color: getDotColour(i) }}>
                        <ResultsText size="lg"> {option.name}</ResultsText>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {new Array(maxNumberOfRows).fill(0).map((_, index) => {
                    return (
                      <tr key={index}>
                        {executiveDecisions.options?.map((option, i) => {
                          const teamForTable = teams.find(
                            (t) => t.teamId === option.teams[index],
                          );
                          return (
                            <td
                              key={i}
                              style={{ color: `${teamForTable?.colour}` }}
                            >
                              <ResultsText size="lg">
                                {" "}
                                {teamForTable?.teamName}
                              </ResultsText>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </ResultsTable>
            </VerticalGroup>
          </InlineGroup>
        </Card>
      </InlineGroup>
    </VerticalGroup>
  );
};

export default RoundResultsExecutiveDecisionsTableTwo;
